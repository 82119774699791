import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

interface myProps {
  isLoading: boolean;
}

export default function Loader(props: myProps) {
  return props.isLoading ? (
    <Backdrop style={{zIndex : 9999}} open={props.isLoading}>
    <div
      style={{
        position : 'absolute',
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        color: "#925B5B",
      }}
    >
        <CircularProgress size="5rem" color="inherit" />
    </div>
    </Backdrop>
  ) : (
    <div />
  );
}