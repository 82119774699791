import React from "react";

import {
    Container,
    Box,
    // Customizable Area Start
    Card,
    CardContent,
    Typography,
    IconButton,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
const configJSON = require("./config");
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { goToPrevPage } from "../../../components/src/Utilities";
// Customizable Area End

import RepostController, { Props } from "./RepostController";

export default class RepostList extends RepostController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
          // Customizable Area Start
          <Box data-test-id="profile-container">
            <NavigationMenu navigation={this.props.navigation} id={this.props.id} />
            <Container style={webStyles.containerStyle}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box display="flex" alignItems="center">
                  <IconButton
                    data-test-id="back-btn"
                    style={webStyles.IconBtnStyle}
                    onClick={() => goToPrevPage()}
                  >
                    <ArrowBackIcon htmlColor="#fff" />
                  </IconButton>
                    <Typography style={webStyles.titleStyle}>
                      {configJSON.profile}
                    </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
          // Customizable Area End
        );
    }
}

// Customizable Area Start
const  webStyles: { [key: string]: React.CSSProperties } = {
    containerStyle: { marginTop: "50px" },
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        alignItems: "center",
        padding: "30px",
        background: "#fff",
        maxWidth: "75%",
        minWidth: '300px',
        gap: "30px",
        flexWrap: "wrap",
    },
    postStyle: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid black",
        padding: "5px",
    },
};
// Customizable Area End
