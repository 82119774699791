import React from "react";
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  Paper,
  IconButton,
  Divider,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import {
  archival,
  community,
  heartfeltImg,
  introImg,
  jewelry,
  landingBanner,
  legacyImage,
  fbIcon,
  instagram,
  youtube,
  twitter_icon,
  linkedIn
} from "./assets";
import CustomButton from "../../../components/src/CustomButton";
import CustomTextField from "../../../components/src/CustomTextField";

const StyledBox = styled(Box)({

  "&.heart-img-container": {
    marginRight: "50px",
    "@media (max-width: 1525px)": {
      marginRight: "50px",
    },
    "@media (max-width: 1475px)": {
      marginRight: "35px",
    },
  },

  "&.banner-container": {
    width: "100%",
    minHeight: "947px",
    background: `linear-gradient(rgba(146, 91, 91, 0.9), rgba(146, 91, 91, 0.9)), url(${landingBanner})`,
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 960px)": {
      minHeight: "500px",
    },
    "@media (max-width: 500px)": {
      minHeight: "300px",
    },
  },

  "&.introduction-container": {
    "@media (max-width: 1280px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  },

  "&.quote-container": {
    width: "55%",
    "@media (max-width: 960px)": {
      width: "65%",
    },
    "@media (max-width: 500px)": {
      width: "75%",
    },
  },

  "&.founder-thought": {
    backgroundColor: "#925B5B",
    width: "100%",
    height: "680px",
    marginTop: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 960px)": {
      height: "580px",
      marginTop: "85px",
    },
    "@media (max-width: 500px)": {
      height: "480px",
      marginTop: "75px",
    },
  },

  "&.craft-container": {
    width: "100%",
    height: "680px",
    marginTop: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 960px)": {
      height: "580px",
      marginTop: "85px",
    },
    "@media (max-width: 500px)": {
      height: "480px",
      marginTop: "75px",
    },
  },

  "&.intro-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      "@media (max-width: 1000px)": {
        width: "500px",
        height: "450px",
      },
      "@media (max-width: 600px)": {
        width: "325px",
        height: "300px",
      },
    },
  },

  "&.heartfelt-img-container": {
    "& img": {
      "@media (max-width: 1450px)": {
        width: "500px",
        height: "450px",
        marginTop: "50px",
      },
      "@media (max-width: 1000px)": {
        width: "400px",
        height: "350px",
      },
      "@media (max-width: 600px)": {
        width: "325px",
        height: "300px",
      },
    },
  },

  "&.paper-container": {
    marginTop: "25px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    margin: "auto",
    flexWrap: "wrap",
    "@media (max-width: 750px)": {
      width: "100%",
    },
  },

  "&.legacy-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "55%",
    "@media (max-width: 960px)": {
      width: "65%",
    },
    "@media (max-width: 500px)": {
      width: "75%",
    },
  },
});

const StyledGrid = styled(Grid)({
  "&.intro-grid": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 1280px)": {
      flexDirection: "column-reverse",
      justifyContent: "center",
    },
  },

  "&.btn-grid": {
    display: "flex",
    alignItems: "center",
    "@media (max-width: 1280px)": {
      justifyContent: "center",
    },
  },

  "&.heartfelt-container": {
    display: "flex",
    alignItems: "center",
    marginTop: "100px",
    backgroundColor: "#F5F1EF",
    "@media (max-width: 1450px)": {
      flexDirection: "column",
    },
  },

  "&.heartfelt-item-container": {
    "@media (max-width: 1450px)": {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center"
    },
  },
});

const StyledTypography = styled(Typography)({
  "&.banner-title": {
    color: "#fff",
    fontSize: "48px",
    fontWeight: 700,
    width: "30%",
    textAlign: "center",
    fontFamily: "Montserrat",
    "@media (max-width: 960px)": {
      fontSize: "36px",
      width: "40%",
    },
    "@media (max-width: 500px)": {
      fontSize: "25px",
      width: "50%",
    },
  },

  "&.introduction-title": {
    fontSize: "36px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    "@media (max-width: 960px)": {
      fontSize: "30px",
    },
    "@media (max-width: 500px)": {
      fontSize: "25px",
    },
  },

  "&.quote-title": {
    fontSize: "36px",
    color: "#fff",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "35px",
    "@media (max-width: 960px)": {
      fontSize: "30px",
    },
    "@media (max-width: 500px)": {
      fontSize: "25px",
    },
  },

  "&.quote-description": {
    fontSize: "24px",
    color: "#fff",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "35px",
    "@media (max-width: 960px)": {
      fontSize: "20px",
    },
    "@media (max-width: 500px)": {
      fontSize: "16px",
    },
  },

  "&.legacy-title": {
    fontSize: "36px",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "35px",
    color: "#292B29",
    marginTop: "20px",
    "@media (max-width: 960px)": {
      fontSize: "30px",
    },
    "@media (max-width: 500px)": {
      fontSize: "25px",
    },
  },

  "&.founder-name": {
    fontSize: "30px",
    color: "#fff",
    fontWeight: 600,
    textAlign: "center",
    "@media (max-width: 960px)": {
      fontSize: "25px",
    },
    "@media (max-width: 500px)": {
      fontSize: "20px",
    },
  },

  "&.founder-designation": {
    fontSize: "20px",
    color: "#fff",
    fontWeight: 400,
    textAlign: "center",
    "@media (max-width: 960px)": {
      fontSize: "16px",
    },
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },

  "&.sublabel-text": {
    marginTop: "50px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Montserrat",
    color: "#475569",
    width: "90%",
  },
});


const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

// Customizable Area End

import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController";


export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  paperData = [
    {
      id: 1,
      imgSrc: archival,
      title: configJSON.digitalArchival,
      desc: configJSON.archivalDescription,
    },
    {
      id: 2,
      imgSrc: community,
      title: configJSON.community,
      desc: configJSON.communityDesc,
    },
    {
      id: 3,
      imgSrc: jewelry,
      title: configJSON.handcrafted,
      desc: configJSON.jewellryDescription,
    },
  ];

  getCelebrateBtn = () => {
    return (
      <CustomButton
        variant="contained"
        color="primary"
        fullWidth
        dataTestId="celebrate-btn"
        btnText={configJSON.btnText}
        onClick={() => this.navigateToPage(configJSON.emailAccountRegistration)}
        style={webStyles.btnStyle}
      />
    );
  };

  // Customizable Area End

  render() {
    return (
      <Box data-test-id="landing-container">
        <NavigationMenu navigation={this.props.navigation} id={this.props.id} />
        <StyledBox className="banner-container">
          <StyledTypography className="banner-title">
            {configJSON.immortalize}
          </StyledTypography>
        </StyledBox>
        <Container
          data-test-id="main-container"
          style={webStyles.containerStyle}
        >
          <StyledGrid className="intro-grid" container>
            <Grid item sm={12} lg={6}>
              <StyledBox className="introduction-container">
                <StyledTypography className="introduction-title">
                  Introducing <br /> Extraordinal Love
                </StyledTypography>

                <StyledTypography className="sublabel-text">
                  {configJSON.introduction}
                </StyledTypography>

                <StyledGrid className="btn-grid" container>
                  <Grid item xs={8} md={6}>
                    {this.getCelebrateBtn()}
                  </Grid>
                </StyledGrid>
              </StyledBox>
            </Grid>
            <Grid item sm={12} lg={6}>
              <StyledBox className="intro-container">
                <img src={introImg} alt="" />
              </StyledBox>
            </Grid>
          </StyledGrid>
        </Container>
        <StyledGrid className="heartfelt-container" container>
          <StyledBox className="heart-img-container">
            <StyledBox className="heartfelt-img-container">
              <img src={heartfeltImg} alt="" />
            </StyledBox>
          </StyledBox>
          <StyledGrid item sm={8} md={6} className="heartfelt-item-container">
            <Typography style={webStyles.inspirationTitle}>
              {configJSON.heartfelt}
            </Typography>

            <Typography style={webStyles.inspirationStyle}>
              {configJSON.inspirationOne}
            </Typography>
            <Typography style={webStyles.inspirationStyle}>
              {configJSON.inspirationTwo}
            </Typography>
            <Typography style={webStyles.inspirationStyle}>
              {configJSON.inspirationThree}
            </Typography>
            <StyledGrid container className="heartfelt-item-container">
              <Grid item xs={12} md={8} lg={6} style={{ marginBottom: "25px" }}>
                {this.getCelebrateBtn()}
              </Grid>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>

        <Typography style={webStyles.ourProduct}>
          {configJSON.ourProducts}
        </Typography>
        <StyledBox className="paper-container">
          {this.paperData.map((item) => {
            return (
              <Paper key={item.id} elevation={0} style={webStyles.paperStyle}>
                <img src={item.imgSrc} alt="" />
                <Typography style={webStyles.paperTitle}>
                  {item.title}
                </Typography>
                <Typography style={webStyles.paperDesc}>{item.desc}</Typography>
              </Paper>
            );
          })}
        </StyledBox>
        <StyledBox>
          <Grid container justifyContent="center">
            <Grid item xs={8} md={6} lg={4}>
              {this.getCelebrateBtn()}
            </Grid>
          </Grid>
        </StyledBox>
        <StyledBox className="founder-thought">
          <StyledBox className="quote-container">
            <StyledTypography className="quote-title">
              {configJSON.founderQuote}
            </StyledTypography>
            <StyledTypography className="quote-description">
              {configJSON.quoteDescription}
            </StyledTypography>
            <StyledTypography className="founder-name">
              {configJSON.founderName}
            </StyledTypography>
            <StyledTypography className="founder-designation">
              {configJSON.founderDesignation}
            </StyledTypography>
          </StyledBox>
        </StyledBox>

        <StyledBox className="craft-container">
          <StyledBox className="legacy-container">
            <img src={legacyImage} alt="" />
            <StyledTypography className="legacy-title">
              {configJSON.craftLegacy}
            </StyledTypography>
            <Typography style={webStyles.quoteDescriptionStyle}>
              {configJSON.quoteDescription}
            </Typography>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                {this.getCelebrateBtn()}
              </Grid>
            </Grid>
          </StyledBox>
        </StyledBox>
        <footer
          style={{
            backgroundColor: "#f5e0d8",
            padding: "40px 0",
            marginTop: "40px",
          }}
        >
          <Container>
            <Grid
              container
              spacing={4}
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: "50px 0px" }}
            >
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "#292b29",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  “A ring is just a ring without a story”
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{
                    color: "#292B29",
                    textAlign: "left",
                    fontWeight: "400",
                  }}
                >
                  -Matthew Benfer, CEO/Founder
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{
                    textAlign: "left",
                    color: "#475569",
                    fontWeight: "400",
                    marginTop: "40px",
                  }}
                >
                  Subscribe to our newsletter for early access to our jewelry
                  line, exclusive updates, and more.
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={8}>
                    <ThemeProvider theme={theme}>
                      <CustomTextField
                        placeholder="Type your email address"
                        fullWidth
                        disabled
                        style={{
                          marginTop: "16px",
                          marginBottom: "16px",
                          backgroundColor: "white",
                          border: "1px solid #CBD5E1",
                          color: "#94A3B8",
                          borderRadius: "8px",
                        }}
                        name={""}
                      />
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      dataTestId="celebrate-btn"
                      btnText="Subscribe"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
                <Grid
                  container
                  justifyContent="flex-start"
                  direction="column"
                  spacing={2}
                >
                  <Grid item>
                    <Box display={"flex"}>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"}>
                          <Grid item>
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "22px",
                                color: "#334155",
                                textDecoration: "none",
                                marginBottom: "8px",
                              }}
                            >
                              About
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "22px",
                                color: "#334155",
                                textDecoration: "none",
                                marginBottom: "8px",
                              }}
                            >
                              Products
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "22px",
                                color: "#334155",
                                textDecoration: "none",
                                marginBottom: "8px",
                              }}
                            >
                              Shop
                            </Typography>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"}>
                          <Grid item>
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "22px",
                                color: "#334155",
                                textDecoration: "none",
                                marginBottom: "8px"
                              }}
                            >
                              Blog
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "22px",
                                color: "#334155",
                                textDecoration: "none",
                                cursor: "pointer",
                                marginBottom: "8px"
                              }}
                              onClick={() => this.navigateToPage("Contactus")}
                            >
                              Contact
                            </Typography>
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  spacing={2}
                  style={{ marginTop: "16px" }}
                >
                  <Grid item>
                    <IconButton>
                      <img src={fbIcon} alt="" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton>
                      <img src={twitter_icon} alt="" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton>
                      <img src={instagram} alt="" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton>
                      <img src={linkedIn} alt="" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton>
                      <img src={youtube} alt="" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{
                backgroundColor: "#C69C9A",
                margin: "10px 0",
              }}
            />

            <Typography
              variant="body2"
              color="textSecondary"
              align="left"
              style={{
                fontWeight: 400,
                fontFamily: "montserrat",
                color: "#475569",
              }}
            >
              Copyright © 2024 Extraordinary Love, All Rights Reserved
            </Typography>
          </Container>
        </footer>
      </Box>
    );
  }
}

// Customizable Area Start

const webStyles: { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "100px" },
  quoteDescriptionStyle: {
    fontSize: "16px",
    color: "#475569",
    fontWeight: 400,
    textAlign: "center",
    marginTop: "25px",
  },

  paperStyle: {
    backgroundColor: "#F5F1EF",
    height: "360px",
    width: "340px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    marginTop: "20px",
    marginInline: "25px",
  },

  paperTitle: {
    fontSize: "24px",
    color: "#292B29",
    fontWeight: 600,
    textAlign: "center",
    margin: "25px 0px",
  },

  paperDesc: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
    color: "#475569",
    width: "85%",
  },

  ourProduct: {
    fontSize: "36px",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "20px",
    color: "#292B29",
    marginTop: "100px",
  },

  btnStyle: { marginTop: "50px" },
  inspirationStyle: {
    marginTop: "40px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Montserrat",
    color: "#475569",
    width: "80%",
  },

  inspirationTitle: {
    fontSize: "36px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginTop: "25px",
    width: "80%",
  },
};

// Customizable Area End
