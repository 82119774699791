import React from "react";
// Customizable Area Start

import { Avatar, Box, createTheme, InputAdornment, styled, TextField, ThemeProvider, Typography } from "@material-ui/core";
import { defaultAvatar } from "../../../blocks/user-profile-basic/src/assets";
import { deleteIcon, postComment } from "./assets";
import CustomToaster from "../../../components/src/CustomToaster";


const StyledInputField = styled(TextField)({
  width: "542px",
  height: "44px",
  marginTop: "10px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "34px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "34px",
  },
  "& .MuiInputBase-input": {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #925B5B",
  },
  "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #925B5B",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    fontSize: "14px",
    color: "#DC2626",
  },
  "& .MuiOutlinedInput-adornedStart": {
    paddingLeft: "0 !important",
    height: "44px",
  },
  "& .MuiOutlinedInput-inputAdornedStart": {
    paddingLeft: "0 !important",
  },
  "@media (max-width: 600px)": {
    width: "400px",
  },
  "@media (max-width: 425px)": {
    width: "325px",
  },
});

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});
import { commentIcon, legacyImage } from "../../../blocks/landingpage/src/assets";
// Customizable Area End

import CommentController, { Props } from "./CommentsController";

export default class Comments extends CommentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (

      <>
        <img
          src={commentIcon}
          alt=""
          data-test-id="comment-icon"
          onClick={() => { this.toggleViewAll(); this.getAllCommentsbyId() }}
          style={{ marginLeft: "15px", cursor: "pointer", position: "absolute", top: "0px", left: "24px" }}
        />
        {Number(this.props.commentCount) + Number(this.state.commentCount) > 0 && (
          <Typography
            style={{
              marginTop: "10px",
              color: "#64748B",
              fontSize: "14px",
              cursor: "pointer",
            }}
            data-test-id="viewAllBtn"
            onClick={() => { this.toggleViewAll(); this.getAllCommentsbyId() }}
          >
            View all {Number(this.props.commentCount) + Number( this.state.commentCount)} comments
          </Typography>
        )}
        <ThemeProvider theme={theme}>
          <StyledInputField
            data-test-id="comment-field"
            variant={"outlined"}
            type={"text"}
            fullWidth
            placeholder={"Add a comment...."}
            onChange={this.handleCommentChange}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                this.postUserComment();
              }
            }}
            value={this.state.userComment}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar
                    src={defaultAvatar}
                    style={{
                      border: "2px solid #F3E1DC",
                      height: "42px",
                      width: "42px",
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {Boolean(this.state.userComment) && (
                    <img
                      src={postComment}
                      style={{ cursor: "pointer" }}
                      onClick={this.postUserComment}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <CustomToaster />
        </ThemeProvider>
        {this.state.viewAllClicked && <Box style={{ maxHeight: "550px", overflowY: "auto" }}>
          {
            this.state.commentList.map((comment) => {
              return (
                <Box position={"relative"} marginBottom={"15px"} marginRight="20px">
                  <Box marginTop="50px" display={"flex"}>
                    <Box>
                      <Avatar
                        alt="profile"
                        src={comment.attributes?.comment_by?.profile_image ?? defaultAvatar}
                        style={{
                          height: "44px",
                          width: "44px",
                          border: "2px solid #F3E1DC",
                        }}
                      />
                    </Box>
                    <Box style={{
                      backgroundColor: "#F1F5F9",
                      display: "flex",
                      padding: "16px",
                      width: "480px",
                      marginLeft: "20px",
                      justifyContent: "space-between",
                      color: "#0F172A",
                      borderRadius: "0px 16px 16px 16px"
                    }}>
                      <Box>

                      <Typography style={{ maxWidth: "380px", marginBottom: "10px", fontSize: "14px" }}>{comment?.attributes?.comment_by?.name}</Typography>
                      <Typography style={{ maxWidth: "380px", fontSize: "14px" }}>{comment?.attributes?.comment}</Typography>
                      </Box>
                      <Typography style={{ fontSize: "14px", color: "#64748B" }}>{comment?.attributes?.created_at}</Typography>
                    </Box>
                  </Box>
                  {comment?.attributes?.delete_icon_flag &&  <img data-test-id="delete-comment" src={deleteIcon} style={{ position: "absolute", right: "0px", cursor: "pointer" }} onClick={() => this.deleteComment(Number(comment?.id))} alt="" />}
                </Box>
              )
            })

          }
          {this.state.commentList?.length === 0 && <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                <img src={legacyImage} height={75} alt="" />
                <Typography
                  style={{
                    fontSize: "16px",
                    marginTop: "15px",
                    marginBottom: "15px",
                    color: "#292B29",
                    fontWeight: 600,
                  }}
                >{`No Comments yet. Be the first one to comment here!`}</Typography>
              </Box>}
        </Box>}
        <button style={{display: "none"}} data-test-id="comment-btn" onClick={this.postUserComment}/>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle: { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px" },

  socialData: {
    marginRight: "40px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },

  profileBtnStyle: {
    width: "196px",
    height: "44px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    marginRight: "15px"
  },

  socialNumberStyle: {
    color: "#292B29",
    fontWeight: 600,
    fontSize: "24px"
  },
  socialFigureName: {
    color: "#292B29",
    fontWeight: 400,
    fontSize: "20px"
  },

  outlinedIcon: {
    height: "50px",
    width: "50px",
    background: "transparent",
    borderRadius: "8px",
    border: "1px solid #925B5B",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },

  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },

  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
    marginLeft: '15px'
  },

};
// Customizable Area End
