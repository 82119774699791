Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start


// Method type
exports.methodTypes = {
  HTTP_GET: "GET",
  HTTP_POST: "POST",
  HTTP_PUT: "PUT",
  HTTP_DELETE: "DELETE"
}


// Endpoints
exports.endpoints = {
  GET_VIDEOS: "bx_block_videos2/videos",
}


exports.validationApiContentType = "application/json";
exports.apiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Videos";
exports.labelBodyText = "Videos Body";
exports.exampleVideoURL = "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
exports.btnExampleTitle = "CLICK ME";
exports.profileTitle = "Profile";
exports.videosTittle = "VIDEOS";
exports.feedTitle = "MORE ON THE FEED";
exports.navigationParam = "navigationBarTitleText";
exports.termParam = "navigationBarTitleText";
exports.tokenExpireMsg = "Token has Expired";
exports.unauthorizedMsg = "Your session has expired, Please login again.";
// Customizable Area End
