export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const changePwd = require("../assets/changePwd.png");
export const contact = require("../assets/contact.png");
export const deleteAcc = require("../assets/deleteAcc.png");
export const drafts = require("../assets/drafts.png");
export const logout = require("../assets/logout.png");
export const notification = require("../assets/notification.png");
export const payments = require("../assets/payments.png");
export const personal = require("../assets/personal.png");
export const privacy = require("../assets/privacy.png");
export const navLinkIcon = require("../assets/navLinkIcon.png");
export const accountIcon = require("../assets/accountIcon.png");
export const actions = require("../assets/actions.png");
export const lockIcon = require("../assets/lockIcon.svg");

