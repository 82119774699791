import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  createTheme,
  InputAdornment,
  styled,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { defaultAvatar } from "../../blocks/user-profile-basic/src/assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  optionIcon,
  postSample,
  postSample2,
  shareIcon,
} from "../../blocks/landingpage/src/assets";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2.web";
import { IPostData } from "../../blocks/landingpage/src/FeedController";
import Comments from "../../blocks/comments/src/Comments";

interface Props {
  description: string;
  liked: boolean;
  likeCount: number;
  likeBy: string;
  likeByUser: {
    id: number;
    email: string;
  };
  postByUser: {
    id: number;
    email: string;
  };
  userProfile: string;
  postMedia: IMedia[];
  commentCount: number;
  postId: number;
  navigationFn: (path: string, param?: string | number) => void;
  isRepost: boolean;
  repostData: {
    data: IPostData;
  };
  postData: IPostData;
}

interface IMedia {
  id: number;
  url: string;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});

const StyledCarousel = styled(Carousel)({
  "& .control-arrow": {
    background: "none !important",
    opacity: "1 !important",
  },
  "& .dot": {
    margin: "0px 4px !important",
    height: "10px !important",
    width: "10px !important",
  },

  "&.reposted": {
    "& .carousel-slider": {
      width: "413px !important",
      marginLeft: "50px"
    },
  }
});


const PostCard = (props: Props) => {
  const {
    userProfile,
    postByUser,
    commentCount,
    description,
    liked,
    postId,
    postMedia,
    navigationFn,
    isRepost,
    repostData,
    postData,
    likeCount,
    likeByUser
  } = props;

  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [mutedVideos, setMutedVideos] = useState<boolean[]>([true]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target as HTMLVideoElement;
          if (entry.isIntersecting) {
            video.play();
          } else {
            video.pause();
          }
        });
      },
      { threshold: 0.75 }
    );

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    return () => {
      observer.disconnect();
    };
  }, []);


  const handleMuteToggle = (index: number) => {
    setMutedVideos((prevMutedVideos) => {
      const newMutedVideos = [...prevMutedVideos];
      newMutedVideos[index] = !newMutedVideos[index];
      return newMutedVideos;
    });
  };

  return isRepost ? (
    <Box width={"542px"} marginBottom={"35px"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"} marginBottom={"25px"}>
          <Avatar
            alt="profile"
            src={userProfile ?? defaultAvatar}
            style={{
              height: "44px",
              width: "44px",
              border: "2px solid #F3E1DC",
            }}
          />
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              style={{
                marginLeft: "16px",
                color: "#0F172A",
                fontSize: "16px",
                fontWeight: 600,
                cursor: "pointer"
              }}
              onClick={() => navigationFn("UserProfileBasicBlock", postByUser?.id)}
            >
              {postByUser?.email?.split("@")[0]} <span style={{ marginLeft: "5px" }}>•</span>
              <span
                style={{
                  color: "#64748B",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
              >
                {postData?.attributes?.created_at}
              </span>{" "}
            </Typography>
            <Typography
              style={{
                marginLeft: "16px",
                marginTop: "10px",
                color: "#475569",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {postData?.attributes?.description}
            </Typography>
          </Box>
        </Box>
        <img src={optionIcon} alt="" style={{ cursor: "pointer" }} />
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"} marginLeft={"25px"}>
          <Avatar
            alt="profile"
            src={repostData?.data?.attributes?.user_profile ?? defaultAvatar}
            style={{
              height: "44px",
              width: "44px",
              border: "2px solid #F3E1DC",
            }}
          />
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              style={{
                marginLeft: "16px",
                color: "#0F172A",
                fontSize: "16px",
                fontWeight: 600,
                cursor: "pointer"
              }}
              onClick={() => navigationFn("UserProfileBasicBlock", repostData?.data?.attributes?.post_by_user?.id)}
            >
              {repostData?.data?.attributes?.post_by_user?.email?.split("@")[0]}
              <span style={{ marginLeft: "5px" }}>•</span>
              <span
                style={{
                  color: "#64748B",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
              >
                {repostData?.data.attributes?.created_at}
              </span>{" "}
            </Typography>
            <Typography
              style={{
                marginLeft: "16px",
                marginTop: "10px",
                color: "#475569",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {repostData?.data?.attributes?.description}
            </Typography>
          </Box>
        </Box>
      </Box>
      <StyledCarousel className="reposted" showThumbs={false} showArrows={repostData?.data?.attributes?.images_and_videos?.length > 1} showStatus={false} showIndicators={repostData?.data?.attributes?.images_and_videos?.length > 1}>
        {repostData?.data?.attributes?.images_and_videos.map((media, index) => {
          return new URL(media.url).searchParams.get("type") === "image" ? (
            <img
              src={media.url}
              style={{
                marginTop: "15px",
                height: "287px",
                width: "413px",
                borderRadius: "12px",

              }}
              alt=""
            />
          ) : (
            <video
              loop
              autoPlay
              ref={(el) => (videoRefs.current[index] = el)}
              muted={mutedVideos[index]}
              onClick={() => handleMuteToggle(index)}
              style={{
                height: "287px",
                width: "413px",
                borderRadius: "12px",
                objectFit: "cover",
                marginTop: "15px",
              }}
            >
              <source src={media?.url} />
            </video>
          );
        })}
      </StyledCarousel>
      <Box style={{ position: "relative" }}>
        <Box display={"flex"} marginTop="15px" marginBottom="10px">
          <Likeapost2
            navigation={undefined}
            id={""}
            postId={repostData?.data?.attributes?.id}
            isLiked={repostData?.data?.attributes?.liked}
          />
          <img
            src={shareIcon}
            alt=""
            onClick={() => navigationFn("Repost", repostData?.data?.attributes?.id)}
            style={{ marginLeft: "55px", cursor: "pointer" }}
          />
        </Box>
        {repostData?.data?.attributes?.like_count > 0 && <Typography
          style={{ color: "#0F172A", fontSize: "16px" }}
        >
          Loved by
          <span style={{ fontWeight: 600, cursor: "pointer" }} onClick={() => navigationFn("UserProfileBasicBlock", repostData?.data?.attributes?.post_by_user?.id)}> {repostData?.data?.attributes?.like_by_user?.email?.split("@")[0]}</span>
          {repostData?.data?.attributes?.like_count > 1 && <span onClick={() => navigationFn("Loves", repostData?.data?.attributes?.id)} style={{ fontWeight: 600, cursor: "pointer" }}> and {repostData?.data?.attributes?.like_count - 1} others</span>}
        </Typography>}
        <Typography
          style={{ color: "#334155", fontSize: "16px", marginTop: "5px" }}
        >
          <span style={{ fontWeight: 600, color: "#0F172A", marginRight: "5px", cursor: "pointer" }} onClick={() => navigationFn("UserProfileBasicBlock", repostData?.data?.attributes?.post_by_user?.id)}>
            {repostData?.data?.attributes?.post_by_user?.email?.split("@")[0]}
          </span>
          {repostData?.data?.attributes?.description}
        </Typography>
        <Comments navigation={undefined} id={""} postId={postId} commentCount={commentCount} />
      </Box>
    </Box>
  ) : (
    <Box width={"542px"} marginBottom={"35px"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"}>
          <Avatar
            alt="profile"
            src={userProfile ?? defaultAvatar}
            style={{
              height: "44px",
              width: "44px",
              border: "2px solid #F3E1DC",
            }}
          />
          <Typography
            style={{
              marginLeft: "16px",
              color: "#0F172A",
              fontSize: "16px",
              fontWeight: 600,
              cursor: "pointer"
            }}
            onClick={() => navigationFn("UserProfileBasicBlock", postByUser?.id)}
          >
            {postByUser?.email?.split("@")[0]}
          </Typography>
        </Box>
        <img src={optionIcon} alt="" style={{ cursor: "pointer" }} />
      </Box>
      {<StyledCarousel showThumbs={false} showArrows={postMedia.length > 1} showStatus={false} showIndicators={postMedia?.length > 1}>
        {postMedia.map((media, index) => {
          return new URL(media.url).searchParams.get("type") === "image" ? (
            <img
              src={media.url}
              style={{
                marginTop: "15px",
                height: "305px",
                width: "542px",
                borderRadius: "5px",
              }}
              alt=""
            />
          ) : (
            <video
              loop
              autoPlay
              ref={(el) => (videoRefs.current[index] = el)}
              onClick={() => handleMuteToggle(index)}
              muted={mutedVideos[index]}
              style={{
                height: "305px",
                width: "542px",
                borderRadius: "5px",
                objectFit: "cover",
                marginTop: "15px",
              }}
            >
              <source src={media?.url} />
            </video>
          );
        })}
      </StyledCarousel>}
      <Box position={"relative"}>
        <Box display={"flex"} marginTop="15px" marginBottom={"15px"}>
          <Likeapost2
            navigation={undefined}
            id={""}
            postId={postId}
            isLiked={liked}
          />
          <img
            src={shareIcon}
            alt=""
            onClick={() => navigationFn("Repost", postId)}
            style={{ marginLeft: "55px", cursor: "pointer" }}
          />
        </Box>
        {likeCount > 0 && <Typography
          style={{ color: "#0F172A", fontSize: "16px" }}
        >
          Loved by
          <span style={{ fontWeight: 600, cursor: "pointer" }} onClick={() => navigationFn("UserProfileBasicBlock", likeByUser?.id)}>&nbsp;{likeByUser?.email?.split("@")[0]} </span>

          {likeCount > 0 && <span style={{ fontWeight: 600, cursor: "pointer" }} onClick={() => navigationFn("Loves", postId)} >and {likeCount - 1} others</span>}
        </Typography>}
        <Typography
          style={{ color: "#334155", fontSize: "16px", marginTop: "5px" }}
        >
          <span style={{ fontWeight: 600, color: "#0F172A", marginRight: "5px", cursor: "pointer" }} onClick={() => navigationFn("UserProfileBasicBlock", postByUser?.id)}>
            {postByUser?.email?.split("@")[0]}
          </span>
          {description}
        </Typography>
        <Comments navigation={undefined} id={""} postId={postId} commentCount={commentCount} />
      </Box>
    </Box>
  );
};

export default PostCard;
