import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import OtpInput from "react-otp-input";
import { Typography } from "@material-ui/core";

interface Props {
  value: string;
  onChange: (otp: string) => void;
  digitCount: number;
  isOtpinvalid: boolean;
  invalidOtpMsg: string;
}

const useStyles = makeStyles(() => ({
  otpInputContainer: {
    justifyContent: "flex-start",
    "& input": {
      border: `1px solid #A6B0BF`,
      borderRadius: "8px",
      width: "70px !important",
      height: "70px",
      fontSize: "24px",
      color: "#925B5B",
      marginRight: "24px",
      "&:hover": {
        borderColor: "#925B5B"
      },
      "&:focus": {
        borderColor: "#925B5B",
        outline: "none"
      },
      "@media (max-width: 900px)": {
        width: "60px !important",
        height: "60px !important",
      },
      "@media (max-width: 600px)": {
        width: "50px !important",
        height: "50px !important",
      },
      "@media (max-width: 400px)": {
        width: "45px !important",
        height: "45px !important",
      },
    },
  },
}));

const CustomOtpInput = (props: Props) => {
  const classes = useStyles(props);

  const { value, onChange, digitCount, isOtpinvalid, invalidOtpMsg } = props;

  return (
    <React.Fragment>
      <OtpInput
        value={value}
        onChange={onChange}
        numInputs={digitCount}
        renderInput={(propsOb) => <input {...propsOb} />}
        containerStyle={classes.otpInputContainer}
        inputType="tel"
      />
      {isOtpinvalid && (
        <Typography style={webStyles.invalidMsg}>{invalidOtpMsg}</Typography>
      )}
    </React.Fragment>
  );
};

const webStyles = {
  invalidMsg: {
    color: "#FF0000",
    marginTop: "7px",
    fontSize: "16px",
  },
};

export default CustomOtpInput;
