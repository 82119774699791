import React from "react";

// Customizable Area Start
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import CustomToaster from "../../../components/src/CustomToaster";
import { goToPrevPage } from "../../../components/src/Utilities";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Formik, Form } from "formik";
import CustomTextField from "../../../components/src/CustomTextField";
import { Avatar, Box, Button, CircularProgress, Container, FormHelperText, Grid, IconButton, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import { camIcon, defaultAvatar, fieldFb, fieldLinkedln, fieldTwitter, notification, search } from "./assets";
import Loader from "../../../components/src/Loader.web";
import CustomWebCamModal from "../../../components/src/CustomWebCamModal";
import CustomEditImageModal from "../../../components/src/CustomEditImageModal";


const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});


// Customizable Area End

import EditUserProfileController, {
  Props,
  configJSON,
} from "./EditUserProfileController";

export default class EditUserProfile extends EditUserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    const data =  this.state.profileData?.attributes

    const intialValues = {
      name: data?.full_name,
      email: data?.email,
      profileHandle: data?.profile_handle,
      location: data?.user_location,
      bio: data?.bio,
      facebook: data?.social_media_link?.facebook_link,
      twitter: data?.social_media_link?.twitter_link,
      linkedin: data?.social_media_link?.linkedin_link
    };

    const { navigation, id } = this.props;
    const { isLoading, profileData, isProfileValidationLoading, openEditImgModal, profileValidationMsg, isValidationError, isWebCamOpen } = this.state;

    return (
      <Box data-test-id="profile-container">
        <NavigationMenu navigation={navigation} id={id} />
        <ThemeProvider theme={theme}>
          <Container style={webStyles.containerStyle}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box display="flex" alignItems="center">
                <IconButton
                  data-test-id="back-btn"
                  style={webStyles.IconBtnStyle}
                  onClick={() => goToPrevPage()}
                >
                  <ArrowBackIcon htmlColor="#fff" />
                </IconButton>
                <Typography style={webStyles.titleStyle}>
                  {configJSON.editProfile}
                </Typography>
              </Box>
              <Box>
                <IconButton style={webStyles.outlinedIcon}>
                  <img src={notification} alt="" />
                </IconButton>
                <IconButton style={webStyles.outlinedIcon}>
                  <img src={search} alt="" />
                </IconButton>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
              style={{ marginTop: "50px" }}
            >
              <Box
                style={{
                  position: "relative",
                  height: "156px",
                  width: "156px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <Box
                  data-test-id="toggleProfile"
                  onClick={() => this.toggleEditProfileImg(true)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "absolute",
                    top: "40%",
                    left: "23%",
                    zIndex: 9999,
                    cursor: "pointer",
                  }}
                >
                  <img src={camIcon} alt="" />
                  <Typography
                    style={{
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Edit picture
                  </Typography>
                </Box>
                <Avatar
                  alt="profile"
                  src={profileData?.attributes.profile_image ?? defaultAvatar}
                  style={{ height: "156px", width: "156px", opacity: 0.6 }}
                />
              </Box>
            </Box>
            {!isLoading && (
              <Box style={{ marginTop: "50px" }}>
                <Formik
                  initialValues={intialValues}
                  onSubmit={(values) => {
                    this.updateUserProfile(values);
                  }}
                >
                  {({ errors, touched, handleChange, handleBlur, values }) => (
                    <Form>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={webStyles.fieldGridStyle}
                      >
                        <Grid item xs={12} md={6}>
                          <CustomTextField
                            name={configJSON.name}
                            type={configJSON.textType}
                            label={configJSON.nameLabel}
                            fullWidth
                            placeholder={configJSON.namePlaceholder}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={webStyles.fieldGridStyle}
                      >
                        <Grid item xs={12} md={6}>
                          <CustomTextField
                            name={configJSON.emailName}
                            type={configJSON.emailType}
                            label={configJSON.emailLabel}
                            fullWidth
                            disabled
                            placeholder={configJSON.emailPlaceHolder}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={webStyles.fieldGridStyle}
                      >
                        <Grid item xs={12} md={6}>
                          <CustomTextField
                            data-test-id="profile-handle"
                            name={configJSON.profileHandle}
                            type={configJSON.textType}
                            label={configJSON.handleLabel}
                            fullWidth
                            placeholder={configJSON.handlePlaceholder}
                            onChange={(event) => {
                              handleChange(event);
                              this.validateProfileHandle(event);
                            }}
                            onBlur={handleBlur}
                            value={values.profileHandle}
                            error={
                              touched.profileHandle && !!errors.profileHandle
                            }
                            helperText={
                              touched.profileHandle && errors.profileHandle
                            }
                          />
                          {isProfileValidationLoading && (
                            <Box
                              style={{
                                color: "#925B5B",
                                marginTop: "5px",
                              }}
                            >
                              <CircularProgress size="20px" color="inherit" />
                            </Box>
                          )}
                          {profileValidationMsg && !isProfileValidationLoading && (
                            <FormHelperText
                              error
                              style={{
                                ...webStyles.formHelperStyle,
                                color: isValidationError ? "#DC2626" : "green",
                              }}
                            >
                              {isValidationError
                                ? configJSON.unavailableMsg
                                : configJSON.availableMsg}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={webStyles.fieldGridStyle}
                      >
                        <Grid item xs={12} md={6}>
                          <CustomTextField
                            name={configJSON.location}
                            type={configJSON.textType}
                            label={configJSON.locationLabel}
                            fullWidth
                            value={values.location}
                            placeholder={configJSON.locationPlaceholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.location && !!errors.location}
                            helperText={touched.location && errors.location}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={webStyles.fieldGridStyle}
                      >
                        <Grid item xs={12} md={6}>
                          <CustomTextField
                            name={configJSON.bio}
                            type={configJSON.textType}
                            label={configJSON.bioLabel}
                            fullWidth
                            placeholder={configJSON.bioPlaceholder}
                            onChange={handleChange}
                            value={values.bio}
                            onBlur={handleBlur}
                            error={touched.bio && !!errors.bio}
                            helperText={touched.bio && errors.bio}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={webStyles.fieldGridStyle}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography
                            style={{
                              color: "#0F172A",
                              fontSize: "20px",
                              fontWeight: 600,
                              margin: "15px 0px",
                            }}
                          >
                            {configJSON.linksTitle}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={webStyles.fieldGridStyle}
                      >
                        <Grid item xs={12} md={6}>
                          <CustomTextField
                            name={configJSON.facebook}
                            type={configJSON.textType}
                            label={configJSON.facebookLabel}
                            fullWidth
                            placeholder={configJSON.facebookPlaceholder}
                            onChange={handleChange}
                            startAdornment={
                              <img
                                src={fieldFb}
                                style={webStyles.fieldIconStyle}
                              />
                            }
                            onBlur={handleBlur}
                            value={values.facebook}
                            error={touched.facebook && !!errors.facebook}
                            helperText={touched.facebook && errors.facebook}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={webStyles.fieldGridStyle}
                      >
                        <Grid item xs={12} md={6}>
                          <CustomTextField
                            name={configJSON.twitter}
                            type={configJSON.textType}
                            label={configJSON.twitterLabel}
                            fullWidth
                            placeholder={configJSON.twitterPlaceholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            startAdornment={
                              <img
                                src={fieldTwitter}
                                style={webStyles.fieldIconStyle}
                              />
                            }
                            value={values.twitter}
                            error={touched.twitter && !!errors.twitter}
                            helperText={touched.twitter && errors.twitter}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={webStyles.fieldGridStyle}
                      >
                        <Grid item xs={12} md={6}>
                          <CustomTextField
                            name={configJSON.linkedin}
                            type={configJSON.textType}
                            label={configJSON.linkedinLabel}
                            fullWidth
                            placeholder={configJSON.linkedinPlaceholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.linkedin}
                            startAdornment={
                              <img
                                src={fieldLinkedln}
                                style={webStyles.fieldIconStyle}
                              />
                            }
                            error={touched.linkedin && !!errors.linkedin}
                            helperText={touched.linkedin && errors.linkedin}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={webStyles.fieldGridStyle}
                      >
                        <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                          <Button
                            type={configJSON.submitType}
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{
                              ...webStyles.btnSubmitStyle,
                              textTransform: "capitalize",
                            }}
                            data-test-id="btn-submit"
                          >
                            {configJSON.saveChanges}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            )}
            <Loader isLoading={isLoading} />
          </Container>
        </ThemeProvider>
        <CustomToaster />
        <CustomWebCamModal
          isOpen={isWebCamOpen}
          processImg={this.processCameraImage}
          handleClose={this.toggleWebCam}
        />

        <CustomEditImageModal
          isOpen={openEditImgModal}
          processImg={this.processCameraImage}
          handleClose={this.toggleEditProfileImg}
          openCamModal={this.toggleWebCam}
        />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles: { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px"},

  socialData: {
    marginRight: "40px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },

  btnSubmitStyle: {
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color: "#fff",
    fontSize: "20px",
    fontWeight: 700,
    display: "inline-block",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    borderRadius: "8px",
    height: "56px",
  },

  profileBtnStyle: {
    width: "196px",
    height: "44px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    marginRight: "15px"
  },

  socialNumberStyle: {
    color: "#292B29",
    fontWeight: 600,
    fontSize: "24px"
  },
  socialFigureName: {
    color: "#292B29",
    fontWeight: 400,
    fontSize: "20px"
  },

  fieldGridStyle: {
    marginBottom: "25px"
  },

  outlinedIcon: {
    height: "50px",
    width: "50px",
    background: "transparent",
    borderRadius: "8px",
    border: "1px solid #925B5B",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },

  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },
  formHelperStyle: {
    marginLeft: 0,
    marginTop: "5px",
    fontSize: "14px",
  },

  fieldIconStyle: {
    marginLeft: "10px"
  },

  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
  },

};
// Customizable Area End
