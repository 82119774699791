import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { uploadClip } from "../../../blocks/postcreation/src/assets";
// Customizable Area End

import Uploadmedia3Controller, {
  Props,
  configJSON,
} from "./Uploadmedia3Controller";

export default class Uploadmedia3 extends Uploadmedia3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <React.Fragment>
        <button style={webStyles.uploadBtnStyle} onClick={this.handleButtonClick}> <img src={uploadClip} alt="" /> <span style={{ marginLeft: "8px" }}> {configJSON.uploadBtnTxt} </span></button>
        <input
          ref={this.fileInputRef}
          data-test-id="file-input"
          type="file"
          accept="image/*, video/*"
          multiple
          onChange={this.handleFileChange}
          style={{ display: "none" }}
        />
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles: { [key: string]: React.CSSProperties } = {
  uploadBtnStyle: {
    position: "absolute",
    fontFamily: "Montserrat",
    fontSize: "15px",
    border: "1px solid #9CAAB1",
    width: "217px",
    height: "31px",
    background: "transparent",
    borderRadius: "7px",
    display: "flex",
    alignItems: "center",
    bottom: 20,
    right: 20,
    color: "#0F172A",
  },
};
// Customizable Area End
