Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.timeout = 3000
exports.bottomLine = "Lorem ipsum dolor sit amet, consectetur adispiscing.\nTurpis mauris euismod posuere scelerisque"
exports.percentageText = "20%";
exports.feedpage = "Feedpage";
exports.landingPage = "LandingPage";
// Customizable Area End