export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const notification = require("../assets/notification.png");
export const search = require("../assets/search.png");
export const defaultAvatar = require("../assets/defaultAvatar.png");
export const locationImg = require("../assets/location.png");
export const copyIcon = require("../assets/copyIcon.png");
export const emailIcon = require("../assets/emailIcon.png");
export const accordionIcon = require("../assets/accordionIcon.png");
export const fbIcon = require("../assets/fbIcon.png");
export const linkedlnIcon = require("../assets/linkedlnIcon.png");
export const twitterLogo = require("../assets/twitterLogo.png");
export const accordionItem = require("../assets/accordionItem.png");
export const post = require("../assets/post.png");
export const postOne = require("../assets/post_one.png");
export const postwo = require("../assets/post_two.png");
export const fieldFb = require("../assets/fieldFb.png");
export const fieldTwitter = require("../assets/fieldTwitter.png");
export const fieldLinkedln = require("../assets/fieldLinkedln.png");
export const camIcon = require("../assets/camIcon.png")