import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOnline: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SplashscreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      isOnline: navigator.onLine,
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    setTimeout(() => {
      if (navigator.onLine) {
        this.navigateFromSplash();
      }
    }, configJSON.timeout);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  }

  navigateToPage = (page: string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMsg);
  };

  navigateFromSplash = async () => {
    const isLoggedIn = await getStorageData("token");
    isLoggedIn
      ? this.navigateToPage(configJSON.feedpage)
      : this.navigateToPage(configJSON.landingPage);
  };

  updateOnlineStatus = () => {
    this.setState({ isOnline: navigator.onLine });
    if (!navigator.onLine) {
      toast.error(
        "You are offline. Please check your connection and try again!"
      );
    } else {
      toast.success("Back online!");
      this.navigateFromSplash();
    }
  };

  handleRetry = () => {
    this.setState({ isOnline: navigator.onLine });
  };
  // Customizable Area End
}
