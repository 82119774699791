import React from "react";

// Customizable Area Start

import { Box, Container, Grid, IconButton, Typography, styled } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import parse from 'html-react-parser';
import CustomToaster from "../../../components/src/CustomToaster";
import CustomButton from "../../../components/src/CustomButton";
import CustomSkeleton from "../../../components/src/CustomSkeleton";
import CustomCheckbox from "../../../components/src/StyledCheckbox"
import { goToPrevPage } from "../../../components/src/Utilities";



const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});




const StyledTypography = styled(Typography)({
  "&.main-title": {
    color: "#292B29",
    fontSize: "48px",
    fontWeight: 700,
    textAlign: "center",
    "@media(max-width:900px)": {
      fontSize: "40px",
    },
    "@media(max-width:600px)": {
      fontSize: "30px",
    },
  },

  "&.policy-content": {
    color: "#64748B",
    fontSize: "20px",
    fontWeight: 400,
    "@media(max-width:900px)": {
      fontSize: "18px",
    },
    "@media(max-width:600px)": {
      fontSize: "16px",
    },
  },

  "&.agreement-statement": {
    fontSize: "20px",
    color: "#0F172A",
    "@media(max-width:900px)": {
      fontSize: "18px",
    },
    "@media(max-width:600px)": {
      fontSize: "16px",
    },
  },
});






// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    const { isPolicyAgreed, policyType, isLoading, policyData } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <IconButton style={webStyles.iconBtnStyle} onClick={() => goToPrevPage()}>
          <ArrowBackIcon htmlColor="#fff" />
        </IconButton>
        <Container data-test-id="terms-container">
          <StyledTypography variant="h1" className="main-title">
            {policyType === configJSON.termPolicyType
              ? configJSON.termsAndConditions
              : configJSON.privacyPolicy}
          </StyledTypography>

          <Box style={webStyles.contentContainerStyle}>
            {isLoading ? (
              <CustomSkeleton
                variant="rect"
                animation="wave"
                style={webStyles.skeletonStyle}
              />
            ) : (
              <StyledTypography className="policy-content">
                {parse(policyData)}
              </StyledTypography>
            )}
          </Box>

          <Box style={webStyles.checkboxContainer}>
            <CustomCheckbox
              data-test-id="terms-checkbox"
              checked={isPolicyAgreed}
              onChange={this.termsCheckboxChange}
            />
            <StyledTypography className="agreement-statement">
              {policyType === configJSON.termPolicyType
                ? configJSON.termsAndConditionStatement
                : configJSON.privacyPolicyStatement}
            </StyledTypography>
          </Box>

          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={4}
            style={webStyles.gridStyle}
          >
            <Grid item xs={12} md={6}>
              <CustomButton
                variant="outlined"
                color="primary"
                fullWidth
                dataTestId="btn-cancel"
                btnText={configJSON.cancel}
                onClick={() => this.navigateToSignUp(false)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomButton
                variant="contained"
                disabled={!isPolicyAgreed}
                color="primary"
                fullWidth
                dataTestId="agree-btn"
                btnText={configJSON.agree}
                onClick={() => this.navigateToSignUp(isPolicyAgreed)}
              />
            </Grid>
          </Grid>
        </Container>
        <CustomToaster />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles: { [key: string]: React.CSSProperties } = {
  contentContainerStyle: { marginTop: "50px" },

  iconBtnStyle: {
    height: "44px",
    width: "44px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    margin: "40px",
  },

  skeletonStyle: { width: "100%", height: "500px", borderRadius: "10px" },

  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "25px",
  },

  gridStyle: { marginTop: "25px" },

};

// Customizable Area End
