Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.exampleAPiGetMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Repost";
exports.labelAddMessageText = "Add Message";
exports.labelBodyText = "Repost Body";
exports.successRepostMessage = "Post reposted successfully!";
exports.errorRepostMessage = "Error in reposting! Please try again";
exports.btnRepost = "Repost";
exports.btnSendPost = "Send";
exports.contentRepostApiContentType = "application/json";
exports.baseURL =
  "https://myfriendsez-216876-ruby.b216876.dev.eastus.az.svc.builder.cafe/";
exports.sendPostEndPoint = "bx_block_repost/reposts";
exports.getPostEndPoint = "bx_block_posts/posts";
exports.sendPostApiMethod = "POST";

exports.repostTitle = "Repost";
exports.postIdParam = "navigationBarTitleText";
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.getPostDetailsbyId = "bx_block_posts/posts";
exports.getCurrUserDetailsEndpoint = "account_block/accounts/logged_user";
exports.fetchErrorMsg = "Something went wrong!";
exports.title = "title";
exports.description = "description";
exports.repostEndpoint = "bx_block_repost/reposts";
exports.repostSuccessfull = "Repost done successfully";
exports.titleMsg = "Please Enter title";
exports.descriptionMsg = "Please Enter description";
exports.repost = "Repost";

// Customizable Area End
