import React from "react";
import {
  Box,
  InputAdornment,
  InputBaseComponentProps,
  InputLabel,
  TextField,
  styled,
} from "@material-ui/core";

interface Props {
  onChange?: (value: unknown) => void;
  onBlur?: (value: unknown) => void;
  name: string;
  value?: unknown;
  type?: "number" | "text" | "email" | "password" | "date";
  placeholder?: string;
  disabled?: boolean;
  helperText?: string | false;
  error?: boolean;
  label?: string;
  inputProps?: InputBaseComponentProps;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  multiline?: boolean;
  minRows?: string | number;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  asterisk?: boolean;
  labelStyle?: GenericStyle;
  readOnly?: boolean;
  subLabel?: string;
  iButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
  ref?:
    | ((instance: unknown) => void)
    | React.RefObject<unknown>
    | null
    | undefined;
}

interface GenericStyle {
  [key: string]: string;
}

const StyledInputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    padding: "10px 8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
  },
  "& .MuiInputBase-input": {
    padding: "10px 8px",
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #925B5B",
  },
  "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #925B5B",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    fontSize: "14px",
    color: "#DC2626",
  },
  "& .MuiOutlinedInput-adornedStart": {
    paddingLeft: "0 !important",
  },
  "& .MuiOutlinedInput-inputAdornedStart": {
    paddingLeft: "0 !important",
  },
});

const CustomTextField = (props: Props) => {
  const {
    onChange,
    ref,
    value,
    type,
    placeholder,
    disabled,
    error,
    helperText,
    multiline,
    minRows,
    label,
    subLabel,
    inputProps,
    endAdornment,
    startAdornment,
    style,
    fullWidth = false,
    asterisk = false,
    labelStyle,
    iButtonClick = undefined,
    readOnly = false,
    onBlur,
    name,
    ...rest
  } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {label && (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <InputLabel
            style={{
              color: "#334155",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
              ...labelStyle,
            }}
          >
            {label} {asterisk && <span style={{ color: "red" }}>*</span>}
          </InputLabel>
        </Box>
      )}
      <StyledInputField
        variant="outlined"
        fullWidth={fullWidth}
        name={name}
        value={value}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        helperText={helperText}
        error={error}
        multiline={multiline}
        minRows={minRows}
        style={style}
        {...rest}
        InputProps={{
          readOnly: readOnly,
          startAdornment: (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default CustomTextField;
