import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  createTheme,
  InputAdornment,
  styled,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { defaultAvatar } from "../../blocks/user-profile-basic/src/assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  commentIcon,
  optionIcon,
  postSample,
  postSample2,
  shareIcon,
} from "../../blocks/landingpage/src/assets";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2.web";
import { IPostData } from "../../blocks/landingpage/src/FeedController";
import Comments from "../../blocks/comments/src/Comments";
import CustomSkeleton from "./CustomSkeleton";

interface Props {
}


const LoadingPostCard = (props: Props) => {

  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [mutedVideos, setMutedVideos] = useState<boolean[]>([true]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target as HTMLVideoElement;
          if (entry.isIntersecting) {
            video.play();
          } else {
            video.pause();
          }
        });
      },
      { threshold: 0.75 }
    );

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    return () => {
      observer.disconnect();
    };
  }, []);


  const handleMuteToggle = (index: number) => {
    setMutedVideos((prevMutedVideos) => {
      const newMutedVideos = [...prevMutedVideos];
      newMutedVideos[index] = !newMutedVideos[index];
      return newMutedVideos;
    });
  };

  return (<Box width={"542px"} marginBottom={"35px"}>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Box display={"flex"} alignItems={"center"}>
        <CustomSkeleton
          variant="circle"
          style={{
            height: "44px",
            width: "44px",
            border: "2px solid #F3E1DC",
          }}
        />
        <CustomSkeleton
          variant="rect"
          style={{
            marginLeft: "16px",
            color: "#0F172A",
            fontSize: "16px",
            fontWeight: 600,
            cursor: "pointer",
            width: "400px",
            height: "16px"
          }}
        />
      </Box>
    </Box>

    <CustomSkeleton
      variant="rect"

      style={{
        marginTop: "15px",
        height: "305px",
        width: "542px",
        borderRadius: "5px",
      }}
    />
    <Box display={"flex"} marginTop="15px" marginBottom={"15px"}>
      <CustomSkeleton variant="circle" height={25} width={25} style={{ marginRight: "20px" }} />
      <CustomSkeleton variant="circle" height={25} width={25} style={{ marginRight: "20px" }} />
      <CustomSkeleton variant="circle" height={25} width={25} style={{ marginRight: "20px" }} />
    </Box>

    <CustomSkeleton
      variant="rect"
      style={{
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 600,
        cursor: "pointer",
        width: "300px",
        height: "16px",
        marginBottom: "10px"
      }}
    />
    <CustomSkeleton
      variant="rect"
      style={{
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 600,
        cursor: "pointer",
        width: "300px",
        height: "16px"
      }}
    />


  </Box>)

};

export default LoadingPostCard;
