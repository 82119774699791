Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start



// Content Type
exports.otpVerificationContentType = "application/json";


// Method Type
exports.postApiMethodType = "POST";


// API endpoints
exports.verifyOTPEndpoint = "bx_block_forgot_password/passwords/verify_otp";



// String Constants

exports.verificationFailedMsg = "Incorrect OTP";
exports.expireOTPMsg = "The provided OTP has expired.";
exports.apiErrorMsg = "Something went wrong!"
exports.pageInfoMsg = "Enter the 5- digit code that we sent to your email.";
exports.verify = "Verify";
exports.goBack = "Go Back";
exports.emailAccountRegistration = "EmailAccountLoginBlock";
exports.defaultOtpDigit = 5;

exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.placeHolderMobile = "Mobile";
exports.labelForgotPassword = "Forgot password ?";
exports.btnTxtEmailLogin = "LOGIN WITH EMAIL";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Mobile no. not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Log in";
// Customizable Area End
