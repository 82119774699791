Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.commentsContentType = "application/json";
exports.commentsApiMethodType = "GET";
exports.commentPOSTAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Comments";
exports.labelBodyText = "Comments Body";
exports.hintCountryCode = "Select Country";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.commentEndPoint = "/comments/comments"
exports.likeCommentEndPoint = "/comments/like_comment"
exports.getCommentsEndpoint = "/bx_block_comments/comments/show_post_comments";
exports.postCommentType = "BxBlockPosts::Post";
exports.postCommentEndpoint = "bx_block_comments/comments";
exports.httpPostMethod = "POST";
exports.fetchErrorMsg = "Something went wrong!";
exports.tokenExpireMsg = "Token has Expired";
exports.unauthorizedMsg = "Your session has expired, Please login again.";
exports.httpGetMethod = "GET";
exports.httpDeleteMethod = "DELETE";
exports.commentsByPostEndpoint = "bx_block_comments/comments/show_post_comments";
exports.deleteCommentEndpoint = "bx_block_comments/comments";
// Customizable Area End
