import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { likedIcon, likedIconFilled } from "./assets";
// Customizable Area End

import Likeapost2Controller, {
  Props,
  configJSON,
} from "./Likeapost2Controller";
import { IconButton } from "@material-ui/core";

export default class Likeapost2 extends Likeapost2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <IconButton
        style={{
          height: `${this.props.height ?? 24}px`,
          width: `${this.props.height ?? 24}px`,
          cursor: "pointer"
        }}
        data-test-id="like-icon"
        onClick={this.handleLikeBtnClick}
      >
        <img
          src={this.state.isCurrentlyLiked ? likedIconFilled : likedIcon}
          alt=""
          height={this.props.height ?? 24}
          width={this.props.width ?? 24}
        />
      </IconButton>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
