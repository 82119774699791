import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { callApi } from "../../../components/src/Utilities";
import { toast } from "react-hot-toast";


interface ProductImage {
  id: number;
  alt: string | null;
  position: number;
  product_id: number;
  created_at: string;
  updated_at: string;
  admin_graphql_api_id: string;
  width: number;
  height: number;
  src: string;
  variant_ids: any[];
}

interface ProductVariant {
  id: number;
  product_id: number;
  title: string;
  price: string;
  position: number;
  inventory_policy: string;
  compare_at_price: string | null;
  option1: string;
  option2: string | null;
  option3: string | null;
  created_at: string;
  updated_at: string;
  taxable: boolean;
  barcode: string | null;
  fulfillment_service: string;
  grams: number;
  inventory_management: string | null;
  requires_shipping: boolean;
  sku: string;
  weight: number;
  weight_unit: string;
  inventory_item_id: number;
  inventory_quantity: number;
  old_inventory_quantity: number;
  admin_graphql_api_id: string;
  image_id: string | null;
}

interface ProductOption {
  id: number;
  product_id: number;
  name: string;
  position: number;
  values: string[];
}

interface Product {
  id: number;
  title: string;
  body_html: string;
  vendor: string;
  product_type: string;
  created_at: string;
  handle: string;
  updated_at: string;
  published_at: string | null;
  template_suffix: string | null;
  published_scope: string;
  tags: string;
  status: string;
  admin_graphql_api_id: string;
  variants: ProductVariant[];
  options: ProductOption[];
  images: ProductImage[];
  image: ProductImage | null;
}

interface ProductListResponse {
  products: Product[];
  meta: {
    current_page: number;
    next_page: number | null;
    prev_page: number | null;
    total_pages: number;
    total_count: number;
  };
  message: string;
}
interface IProductResponse {
  link: string;
}



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  shopifyProducts: Product[];
  fetchProductLoading: boolean;
  currProductLink: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApiIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiExampleCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      shopifyProducts: [],
      fetchProductLoading: false,
      currProductLink: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.setState({
      isLoading: false,
      fetchProductLoading: false
    })

    if (errorReponse || responseJson?.errors || responseJson?.error) {
      this.apiFailureCallBacks();
    }

    else if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    const header = {};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiExampleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getShopifyProductsId: string = "";
  fetchProductId: string = "";


  async componentDidMount() {
    super.componentDidMount();
    this.getShopifyProducts();
  }

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ProductListResponse & IProductResponse) => {
    if (apiRequestCallId === this.getShopifyProductsId) {
      this.setState({
        shopifyProducts: responseJson?.products
      })
      if (responseJson?.products?.length > 0){
        this.fetchProduct(String(responseJson?.products[0]?.id))
      }
    }

    if (apiRequestCallId === this.fetchProductId) {
      this.setState({
        currProductLink: responseJson?.link
      })
    }
  }

  apiFailureCallBacks = () => {
    toast.error(configJSON.fetchErrorMsg);
  }

  getShopifyProducts = async () => {
    const authToken = await getStorageData("token");
    this.setState({ isLoading: true});

    this.getShopifyProductsId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${(configJSON.getShopifyProductsEndpoint as string)}?page=1&per_page=5`,
      headers: { "token": authToken},
    }, runEngine);
  }


  fetchProduct = async (productId: string) => {
    const authToken = await getStorageData("token");

    this.fetchProductId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${(configJSON.fetchProductEndpoint as string)}/${productId}`,
      headers: { "token": authToken},
    }, runEngine);
  }


  shopNowClick = () => {
    window.open(this.state.currProductLink, '_blank');
  }

  handleCarouselChange = (index: number) => {
    const currSelectedProd = this.state.shopifyProducts[index];
    const productId = currSelectedProd?.id
    this.fetchProduct(String(productId));
  }

  // Customizable Area End
}
