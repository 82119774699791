import React from "react";
// Customizable Area Start
import { Accordion, AccordionDetails, AccordionSummary, Drawer, Avatar, Box, Container, Divider, IconButton, Tab, Tabs, Typography, styled } from "@material-ui/core";
import { goToPrevPage } from "../../../components/src/Utilities";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { accordionIcon, accordionItem, copyIcon, defaultAvatar, emailIcon, fbIcon, linkedlnIcon, locationImg, notification, post, postOne, postwo, search, twitterLogo } from "./assets";
import CustomButton from "../../../components/src/CustomButton";
const configJSON = require("./config");
import CustomSkeleton from "../../../components/src/CustomSkeleton";
import CustomToaster from "../../../components/src/CustomToaster";
import { legacyImage } from "../../../blocks/landingpage/src/assets";
import GroupAddIcon from '@material-ui/icons/GroupAdd';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#925B5B"
  },
});


const StyledTab = styled(Tab)({
  fontFamily: "Montserrat, sans-serif",
  fontSize: "18px",
  fontWeight: 400,
  textTransform: "none",
  "&.Mui-selected": {
    color: "#925B5B",
    fontWeight: 600
  },
});


const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{padding: "24px 0px"}}>{children}</Box>}
    </Typography>
  );
}



// Customizable Area End

import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";


export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  getProfileDescriptionSection = () => {
    const { userProfileData, isViewingOtherProfile, isLoading } = this.state;
    const userData = userProfileData?.attributes;

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        style={{ marginTop: "50px" }}
      >
        {isLoading ? (
          <CustomSkeleton variant={"circle"} height={156} width={156} />
        ) : (
          <Avatar
            alt="profile"
            src={userData?.profile_image ?? defaultAvatar}
            style={{ height: "156px", width: "156px" }}
          />
        )}
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{ marginLeft: "100px" }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            style={{ marginBottom: "25px" }}
          >
            {isLoading ? (
              <CustomSkeleton
                variant={"rect"}
                height={50}
                style={{
                  marginBottom: "10px",
                  width: "100%",
                  borderRadius: "10px",
                }}
              />
            ) : (
              <>
                <Box style={webStyles.socialData}>
                  <Typography style={webStyles.socialNumberStyle}>
                    {userData?.user_galary?.posts_count ?? 0}
                  </Typography>
                  <Typography style={webStyles.socialFigureName}>
                    {configJSON.posts}
                  </Typography>
                </Box>
                <Box style={webStyles.socialData}>
                  <Typography style={webStyles.socialNumberStyle}>
                    {userData?.followers_following?.followers ?? 0}
                  </Typography>
                  <Typography style={webStyles.socialFigureName}>
                    {configJSON.followers}
                  </Typography>
                </Box>
                <Box style={webStyles.socialData}>
                  <Typography style={webStyles.socialNumberStyle}>
                    {userData?.followers_following?.following ?? 1}
                  </Typography>
                  <Typography style={webStyles.socialFigureName}>
                    {configJSON.following}
                  </Typography>
                </Box>
              </>
            )}
          </Box>

          {isLoading ? (
            <>
              <CustomSkeleton
                variant={"rect"}
                height={25}
                style={{ marginBottom: "10px", borderRadius: "10px" }}
              />
              <CustomSkeleton
                variant={"rect"}
                height={25}
                style={{ marginBottom: "10px", borderRadius: "10px" }}
              />{" "}
            </>
          ) : (
            <>
              <Typography
                style={{
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                {userData?.profile_handle
                  ? "@" + userData?.profile_handle
                  : configJSON.noHandle}
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  color: "#292B29",
                  fontWeight: 600,
                  alignItems: "center",
                  display: "flex",
                  marginBottom: "10px",
                }}
              >
                <img src={locationImg} alt="" style={{ marginRight: "5px" }} />
                {userData?.user_location ?? configJSON.noLocation}
              </Typography>
            </>
          )}

          {isLoading ? (
            <CustomSkeleton
              variant={"rect"}
              height={150}
              style={{ marginBottom: "30px" }}
            />
          ) : (
            <Box style={{ width: "80%" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  color: "#64748B",
                  marginBottom: "20px",
                }}
              >
                {userData?.bio ?? configJSON.noBio}
              </Typography>
            </Box>
          )}

          <Box>
            <CustomButton
              variant={userData?.is_follwed ? "outlined" : "contained"}
              color="primary"
              fullWidth
              dataTestId="btn-edit"
              btnText={this.getBtnText(userData?.is_follwed)}
              style={webStyles.profileBtnStyle}
              onClick={() =>
                isViewingOtherProfile
                  ? this.handleFollowReq(userData?.is_follwed)
                  : this.navigateToPage(configJSON.editUserProfile)
              }
            />
            <CustomButton
              variant="outlined"
              data-test-id="copy-icon"
              color="primary"
              fullWidth
              dataTestId="btn-cancel"
              btnText={configJSON.shareProfile}
              onClick={this.shareProfile}
              style={webStyles.profileBtnStyle}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  getImageView = () => {

    return (this.state.userProfileData &&
      this.state.userProfileData.attributes.user_galary.images
        .length > 0
      ? this.state.userProfileData?.attributes.user_galary?.images.map(
        (item, index) =>
          index <= 2 && (
            <img
              key={item}
              src={item}
              style={{
                height: "229px",
                width: "229px",
                marginRight: "4px",
                marginTop: "4px",
                cursor: "pointer",
              }}
              alt={""}
            />
          )
      )
      : this.getNoDataView(configJSON.postTab))

  }

  getVideoView = () => {
    return (this.state.userProfileData &&
      this.state.userProfileData.attributes.user_galary.videos
        .length > 0
        ? this.state.userProfileData?.attributes.user_galary?.videos.map(
            (item, index) =>
              index <= 2 && (
                <video
                  autoPlay={false}
                  controls
                  key={item}
                  style={{
                    height: "229px",
                    width: "229px",
                    marginRight: "4px",
                    marginTop: "4px",
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                >
                  {" "}
                  <source
                    src={item}
                    style={{ height: "100%" }}
                  />{" "}
                </video>
              )
          )
        : this.getNoDataView(configJSON.videosTab))
  }

  getPostView = () => {
    return(
      this.state.userProfileData &&
      this.state.userProfileData.attributes.user_galary.posts?.data.length > 0
      ? this.state.userProfileData?.attributes.user_galary?.posts.data.map(
        (item, index) => {
          const srcUrl =
            item.attributes?.images_and_videos &&
            item?.attributes?.images_and_videos[0]?.url;
            return  index <= 2 && item?.attributes?.images_and_videos?.length > 0 &&
            this.getTypeofMedia(srcUrl) === "video" ? (
            <video
              autoPlay={false}
              controls
              key={srcUrl}
              style={{
                height: "229px",
                width: "229px",
                marginRight: "4px",
                marginTop: "4px",
                cursor: "pointer",
                objectFit: "cover",
              }}
            >
              {" "}
              <source
                src={srcUrl}
                style={{ height: "100%" }}
              />{" "}
            </video>
          ) : (
            <img
              key={item.id}
              src={srcUrl}
              style={{
                height: "229px",
                width: "229px",
                marginRight: "4px",
                marginTop: "4px",
                cursor: "pointer",
              }}
              alt={""}
            />
          );
        }
      )
      : this.getNoDataView(configJSON.postTab)
    )
  }

  getNoDataView = (tabName: string) => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <img src={legacyImage} alt="" />
        <Typography
          style={{
            fontSize: "20px",
            marginTop: "15px",
            color: "#292B29",
            fontWeight: 600,
          }}
        >{`No ${tabName} yet`}</Typography>
      </Box>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start

    const { navigation, id } = this.props;
    const { userProfileData, tabValue, isViewingOtherProfile } = this.state;
    const userData = userProfileData?.attributes;

    // Customizable Area End

    return (
      <Box data-test-id="profile-container">
        <NavigationMenu navigation={navigation} id={id} />
        <Container style={webStyles.containerStyle}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box display="flex" alignItems="center">
              <IconButton
                data-test-id="back-btn"
                style={webStyles.IconBtnStyle}
                onClick={() => goToPrevPage()}
              >
                <ArrowBackIcon htmlColor="#fff" />
              </IconButton>
              {!isViewingOtherProfile && (
                <Typography style={webStyles.titleStyle}>
                  {configJSON.profile}
                </Typography>
              )}
            </Box>
            {!isViewingOtherProfile && <Box position={"relative"}>
              <IconButton style={webStyles.outlinedIcon} data-test-id="drawer-btn" onClick={() => this.switchDrawer(true)}>
              <GroupAddIcon htmlColor="#925B5B" />
              <Typography style={{
                position: "absolute",
                width: "fit-content",
                height: "12px",
                top: "-12px",
                right: "-15px",
                color: "#fff",
                background: "#925B5B",
                padding: "5px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center"
              }} >{this.state.requestList?.length}</Typography>
              </IconButton>
            </Box>}
          </Box>

          {this.getProfileDescriptionSection()}

          <Divider style={{ backgroundColor: "#CBD5E1", margin: "50px 0px" }} />

          <Box display="flex" style={{ marginBottom: "50px" }}>
            {!isViewingOtherProfile && (
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box display={"flex"}>
                  <Box
                    style={{
                      height: "48px",
                      width: "48px",
                      backgroundColor: "#F1F5F9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginInline: "15px",
                    }}
                  >
                    <img src={emailIcon} alt="" />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      marginRight: "15px",
                    }}
                  >
                    <Typography style={{ color: "#64748B", fontSize: "16px" }}>
                      {configJSON.email}
                    </Typography>
                    <Typography
                      style={{
                        color: "#334155",
                        fontSize: "16px",
                        fontWeight: 600,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {userData?.email}
                    </Typography>
                  </Box>
                  <IconButton
                    data-test-id="copyEmail"
                    onClick={() => this.copyText(userData?.email)}
                  >
                    <img src={copyIcon} alt="" />
                  </IconButton>
                </Box>

                <Accordion
                  defaultExpanded
                  style={{
                    marginTop: "25px",
                    backgroundColor: "none !important",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<img src={accordionIcon} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontWeight: 600,
                        color: "#292B29",
                      }}
                    >
                      {configJSON.socialLinks}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display={"flex"} flexDirection={"column"}>
                      {userData?.social_media_link?.facebook_link && (
                        <Box display={"flex"} style={{ marginBottom: "25px" }}>
                          <Box
                            style={{
                              height: "48px",
                              width: "48px",
                              backgroundColor: "#F1F5F9",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginInline: "15px",
                            }}
                          >
                            <img src={fbIcon} alt="" />
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              marginRight: "15px",
                              width: "120px",
                            }}
                          >
                            <Typography
                              style={{
                                color: "#64748B",
                                fontSize: "16px",
                                textTransform: "none",
                              }}
                            >
                              {configJSON.facebooktxt}
                            </Typography>
                            <Typography
                              style={{
                                color: "#334155",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              {`${this.extractUsernameFromURL(
                                userData?.social_media_link?.facebook_link,
                                "FACEBOOK"
                              )}`}
                            </Typography>
                          </Box>
                          <IconButton
                            data-test-id="fb-btn"
                            style={{ marginLeft: "15px" }}
                            onClick={() =>
                              this.handleSocialHandleClick(configJSON.facebook)
                            }
                          >
                            <img src={accordionItem} alt="" />
                          </IconButton>
                        </Box>
                      )}
                      {userData?.social_media_link?.twitter_link && (
                        <Box display={"flex"} style={{ marginBottom: "25px" }}>
                          <Box
                            style={{
                              height: "48px",
                              width: "48px",
                              backgroundColor: "#F1F5F9",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginInline: "15px",
                            }}
                          >
                            <img src={twitterLogo} alt="" />
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              marginRight: "15px",
                              width: "120px",
                            }}
                          >
                            <Typography
                              style={{ color: "#64748B", fontSize: "16px" }}
                            >
                              {configJSON.twittertxt}
                            </Typography>
                            <Typography
                              style={{
                                color: "#334155",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              {`${this.extractUsernameFromURL(
                                userData?.social_media_link?.twitter_link,
                                "TWITTER"
                              )}`}
                            </Typography>
                          </Box>
                          <IconButton
                            data-test-id="twitter-btn"
                            style={{ marginLeft: "15px" }}
                            onClick={() =>
                              this.handleSocialHandleClick(configJSON.twitter)
                            }
                          >
                            <img src={accordionItem} alt="" />
                          </IconButton>
                        </Box>
                      )}
                      {userData?.social_media_link?.linkedin_link && (
                        <Box display={"flex"} style={{ marginBottom: "25px" }}>
                          <Box
                            style={{
                              height: "48px",
                              width: "48px",
                              backgroundColor: "#F1F5F9",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginInline: "15px",
                            }}
                          >
                            <img src={linkedlnIcon} alt="" />
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              marginRight: "15px",
                              width: "120px",
                            }}
                          >
                            <Typography
                              style={{ color: "#64748B", fontSize: "16px" }}
                            >
                              {configJSON.linkedln}
                            </Typography>
                            <Typography
                              style={{
                                color: "#334155",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              {`${
                                this.extractUsernameFromURL(
                                  userData?.social_media_link?.linkedin_link,
                                  "LINKEDIN"
                                ) ?? userData?.full_name
                              }`}
                            </Typography>
                          </Box>
                          <IconButton
                            data-test-id="linkedln-btn"
                            style={{ marginLeft: "15px" }}
                            onClick={() =>
                              this.handleSocialHandleClick(configJSON.linkedin)
                            }
                          >
                            <img src={accordionItem} alt="" />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}

            <Box
              style={{
                marginLeft: "75px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box>
                <StyledTabs
                  data-test-id="media-tabs"
                  value={tabValue}
                  onChange={this.handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <StyledTab label={configJSON.galleryTab} />
                  <StyledTab label={configJSON.postTab} />
                  <StyledTab label={configJSON.videosTab} />
                  <StyledTab label={configJSON.repostTab} />
                </StyledTabs>
                <TabPanel value={tabValue} index={0}>
                  {this.getImageView()}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  {this.getPostView()}
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  {this.getVideoView()}
                  {this.state.userProfileData &&
                    this.state.userProfileData.attributes.user_galary.videos
                      .length > 0 && (
                      <Typography
                        onClick={() => this.navigateToPage("Videos")}
                        style={{
                          marginTop: "25px",
                          cursor: "pointer",
                          fontWeight: 600,
                          display: "flex",
                          justifyContent: "center",
                          color: "#292B29",
                          fontSize: "20px",
                        }}
                        data-test-id = "viewall"
                      >
                        {" "}
                        View all {`>`}{" "}
                      </Typography>
                    )}
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  {this.getNoDataView(configJSON.repostTab)}
                </TabPanel>
                {Number(userData?.post_count) > 3 && (
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#292B29",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    {configJSON.viewAll} <img src={accordionItem} alt="" />
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
        <Drawer
          open={this.state.isDrawerOpen}
          onClose={() => this.switchDrawer(false)}
          anchor="right"
        >
          <Box margin={"35px"}>
            <Typography style={{ fontWeight: 600, fontSize: "18px", display: "flex", justifyContent: "center", marginBottom: "25px" }}>Follow Requests</Typography>
            {
              this.state.requestList.map((req) => {
                return (
                  <Box marginBottom={"20px"}>
                    <Box display={"flex"}>
                      <Avatar
                        src={req?.attributes?.profile_image ?? defaultAvatar}
                        style={{ height: "25px", width: "25px", marginRight: "10px" }}
                      />
                      <Typography style={{ fontSize: "16px", fontWeight: 600, marginBottom: "10px", cursor: 'pointer' }}>{req.attributes?.email?.split("@")[0]}</Typography>
                    </Box>
                    <Box>
                      <CustomButton
                        variant={"contained"}
                        color="primary"
                        fullWidth
                        dataTestId="accept-req"
                        btnText={"Accept"}
                        onClick={() => this.updateReqStatus("accepted", Number(req?.id))
                        }
                        style={webStyles.reqStatus}
                      />
                      <CustomButton
                        variant="outlined"
                        data-test-id="copy-icon"
                        color="primary"
                        fullWidth
                        dataTestId="reject-btn"
                        btnText={"Reject"}
                        onClick={() => this.updateReqStatus("rejected", Number(req?.id))}
                        style={webStyles.reqStatus}
                      />
                    </Box>
                  </Box>
                )
              })
            }

            {
              this.state.requestList?.length === 0 && <Typography style={{ fontWeight: 600, fontSize: "18px", display: "flex", justifyContent: "center", marginBottom: "25px" }}>No Follow Requests yet</Typography>
            }

          </Box>
        </Drawer>
        <button style={{display: "none" }} data-test-id="handle-follow-req" onClick={() => this.handleFollowReq(undefined)} />
        <CustomToaster />
      </Box>
    );
    // Customizable Area End
  }
}



// Customizable Area Start
const webStyles: { [key: string]: React.CSSProperties } = {
  containerStyle: { marginTop: "50px" },

  socialData: {
    marginRight: "40px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },

  reqStatus: {
    width: "106px",
    height: "30px",
    borderRadius: "5px",
    fontSize: "14px",
    fontWeight: 600,
    marginRight: "7px",
    cursor: "pointer"
  },

  profileBtnStyle: {
    width: "196px",
    height: "44px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    marginRight: "15px"
  },

  socialNumberStyle: {
    color: "#292B29",
    fontWeight: 600,
    fontSize: "24px"
  },
  socialFigureName: {
    color: "#292B29",
    fontWeight: 400,
    fontSize: "20px"
  },
  drawerImgStyle: {
    height: "25px",
    width: "100px"
  },

  outlinedIcon: {
    height: "50px",
    width: "50px",
    background: "transparent",
    borderRadius: "8px",
    border: "1px solid #925B5B",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },

  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },

  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
    marginLeft: '15px'
  },

};
// Customizable Area End
