import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  variant: 'text' | 'rect' | 'circle';
  animation?: 'pulse' | 'wave' | false;
  height?: number | string;
  width?: number | string;
  style?: React.CSSProperties;
  className?: string;
}

const CustomSkeleton = (props: Props) => {
  const { variant, animation, height, width, style, className } = props;

  return (
    <Skeleton
      variant={variant}
      animation={animation ?? 'wave'}
      height={height}
      width={width}
      style={style}
      className={className}
    />
  );
};

export default CustomSkeleton;
