import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { callApi } from "../../../components/src/Utilities";
import { toast } from "react-hot-toast";
import { createRef, RefObject } from "react";

interface VideoMetadata {
  title: string;
  description: string;
}

type VideoEntry = [string, VideoMetadata];

type VideoData = VideoEntry[];
interface VideoResponse {
  data: VideoData;
  meta: Meta;
  message: string;
}

interface Meta {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}

interface VideoResponse {
  data: VideoData;
  meta: Meta;
  message: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currPage: number;
  isLoading: boolean;
  videoList: VideoData;
  selectedVideo: string;
  durationData: number[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideosController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  observer: IntersectionObserver | null = null;
  loaderRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currPage: 1,
      isLoading: false,
      videoList: [],
      selectedVideo: "",
      durationData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (responseJson?.errors  || errorReponse || responseJson?.error) {
      this.apiFailureCallBacks(responseJson);
    }

    else if (responseJson && !responseJson.errors) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getRelatedVideosId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    const videoURL = await getStorageData("selectedVideo");
    if (videoURL) {
      this.setState({
        selectedVideo: videoURL
      })
    }
    this.getRelatedVideos();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !this.state.isLoading) {
        this.loadMoreItems();
      }
    });

    if (this.loaderRef.current) {
      this.observer.observe(this.loaderRef.current);
    }

  }


   async componentWillUnmount() {
    removeStorageData("selectedVideo");
    this.setState({
      selectedVideo: ""
    })
    if (this.observer && this.loaderRef.current) {
      this.observer.unobserve(this.loaderRef.current);
    }
  }


  loadMoreItems = () => {
    this.setState(prevState => ({ currPage: prevState.currPage + 1 }), () => this.getRelatedVideos());
  };


  getRelatedVideos = async () => {
    const authToken = await getStorageData("token");
    this.setState({ isLoading: true});
    const { currPage }  = this.state;

    this.getRelatedVideosId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.methodTypes.HTTP_GET,
      endPoint: `${(configJSON.endpoints.GET_VIDEOS as string)}?per_page=${16}&page=${currPage}&type=videos`,
      headers: { "token": authToken},
    }, runEngine);
  }

  apiFailureCallBacks = (responseJson: {errors: [{token: string}]}) => {
    if(responseJson?.errors && responseJson.errors[0]?.token === configJSON.tokenExpireMsg || responseJson.errors[0]?.token === "Invalid token"){
      toast.error(configJSON.unauthorizedMsg);
      this.navigateToPage("EmailAccountLoginBlock");
      return;
    }
    toast.error(configJSON.fetchErrorMsg);
    this.setState({ isLoading: false });
  }

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: VideoResponse) => {
    if (apiRequestCallId === this.getRelatedVideosId) {
      this.getDurations([...this.state.videoList, ...responseJson?.data])
      this.setState(prevState => ({ isLoading: false, videoList: [...prevState.videoList, ...responseJson?.data] }));
    }
  }

  getDurations = (videos: VideoData) => {
    let durationArr: number[] = [];
    videos.forEach(async (video) => {
      const duration = await this.getVideoDuration(video[0]);
      durationArr = [...durationArr, Number(duration) ];
      this.setState({
        durationData: durationArr
      })
    })
  }


  formatVideoDuration = (duration: number) => {
    const totalSeconds = Math.floor(duration);
    const minutes = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;

    return `${minutes}:${secs.toString().padStart(2, '0')}`;
  }

  getVideoDuration = (url: string) => {
    return new Promise((resolve) => {
      const videoElement = document.createElement('video');
      videoElement.src = url;

      videoElement.addEventListener('loadedmetadata', () => {
        resolve(videoElement.duration);
      });
    });
  };


  navigateToPage = (path: string, page?: string) => {
    const navigateToHomePageMsg = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateToHomePageMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      path
    );
    navigateToHomePageMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    navigateToHomePageMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), page);
    this.send(navigateToHomePageMsg);
  };


  playVideo = async (url: string) => {
    this.setState({
      selectedVideo: url
    })
    await setStorageData("selectedVideo", url)
    this.navigateToPage("Videos", "Selected")
  }

  // Customizable Area End
}
