import React from "react";

// Customizable Area Start
import { Box, Button, Container, Grid, withStyles } from "@material-ui/core";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OtpInput from 'react-otp-input';
import { twoFaImg } from "./assets";
// Customizable Area End

export class ForgotPasswordOTPBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
     <>
      <Box style={{position:'relative' as 'relative'}}>
        <Box style={{position:'absolute' as 'absolute',top:'30px',left:'30px'}} >
          <Button style={webStyles.backBtnStyle}><ArrowBackIcon /></Button>
        </Box >
     </Box>

    <Box style={webStyles.parentBox}>
      <Container>
        <Grid  container spacing={4} alignItems="center">
          <Grid item sm={12} md={6}>
          <Box>
            <h1 style={webStyles.titleText}>Two-Factor <br />Authentication</h1>
            <p>Enter the 5-digit code that we sent to your email.</p>

            <Box style={{width:'70%',margin:"30px 0"}}>
                <OtpInput
                    value={this.state.otp}
                    data-test-id={"handleChangeOTP"}
                    onChange={this.handleChangeOTP}
                    numInputs={5}
                    shouldAutoFocus={true}
                    inputStyle={{
                      border: `1px solid #CBD5E1`,
                      borderRadius: "8px",
                      width: "70px",
                      height: "70px",
                      fontSize: '0.85rem',
                      color: "#000",
                      gap: "8px"
                    }}
                    containerStyle={{
                      display: "flex",
                      justifyContent:'space-between',
                      marginRight:'50px'
                    }}
                    renderInput={(props:any) => <Grid item xs={1}><input {...props} /></Grid>}
                />
            </Box>
            <Box marginBottom={'30px'} >
                <Button style={webStyles.buttonStyles} onClick={this.handleSubmitOTP} data-test-id="submitButton" >Verify</Button>
              </Box>  
            <Box>
              <Button style={webStyles.goBackStyles} onClick={this.handleBacktoSignIn} data-test-id="submitButton" >Go Back</Button>
            </Box>        

          </Box>  
          </Grid>
          <Grid item sm={12} md={6}>
          <Box style={{textAlign:'end' as 'end'}}>
            <img src={twoFaImg} alt="sign up page image logo" />
          </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
     </>
      // Customizable Area End
    );
  }

}

 // Customizable Area Start
 const webStyles = {
  parentBox:{
    padding:'80px 0'
  },
  titleText:{
    fontSize:'48px',
    fontWeight:700
  },
  plainText:{
    fontSize:'20px',
    lineHeight:'28px',
    fontWeight:400
  },
  fieldLabel:{
    marginBottom:'40px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap:'10px'
  },
  errorText: {
    color: 'red',
    fontSize: '12px',
    
  },
  passwordCriteria:{
    display :'flex',
    gap:'5px',
    alignItems:'center'
  },
  anchorTagStyles:{
    color:'#925B5B',
    fontSize:'16px',
    fontWeight:600,
    lineHeight:'5px'
  },
  commonTextStyles:{
    fontSize:'16px',
    fontWeight:400

  },
  buttonStyles:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    fontSize:'20px',
    fontWeight:700,
    display: "inline-block",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    padding:'16px 0',
    borderRadius:'8px',
    textTransform:'capitalize' as 'capitalize'
  },
  backBtnStyle:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    minWidth:'44px',
    borderRadius:'8px'
  },
 goBackStyles:{
  background: "transparent",
    color:'#925B5B',
    fontSize:'20px',
    fontWeight:700,
    display: "inline-block",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    padding:'16px 0',
    border:"1px solid #925B5B",
    borderRadius:'8px',
    textTransform:'capitalize' as 'capitalize'
 }
 }
 export default ForgotPasswordOTPBlock;
 
 // Customizable Area End
