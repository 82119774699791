import React from "react";

// Customizable Area Start
import { Container, Box, Typography, IconButton } from "@material-ui/core";
import CustomVideoPlayer from "../../../components/src/CustomVideoPlayer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { goToPrevPage } from "../../../components/src/Utilities";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { playIcon } from "./assets";
// Customizable Area End

import VideosController, { Props, configJSON } from "./VideosController";

export default class Videos extends VideosController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu navigation={this.props.navigation} id={"1"} />
        <Container style={webStyles.containerStyle}>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton
              data-test-id="btn-back"
              style={webStyles.IconBtnStyle}
              onClick={() => goToPrevPage()}
            >
              <ArrowBackIcon htmlColor="#fff" />
            </IconButton>
            <Typography style={webStyles.titleStyle}>
              {configJSON.profileTitle}
            </Typography>
          </Box>
          <Typography style={webStyles.videoStyle}>
            {configJSON.videosTittle}
          </Typography>
          {Boolean(this.state.selectedVideo) ? (
            <>
              <CustomVideoPlayer videoURL={this.state.selectedVideo} />
              <Typography style={webStyles.videoStyle}>
                {configJSON.feedTitle}
              </Typography>
              <Box style={webStyles.videoContainer}>
                {this.state.videoList.map((video, index) => (
                  <Box style={webStyles.videoItem} key={index}>
                    <Box data-test-id="video-container" onClick={() => this.playVideo(video[0])} style={{ position: "relative", cursor: "pointer" }}>
                      <video style={webStyles.video}>
                        <source src={video[0]} type="video/mp4" />
                      </video>
                      <Typography style={webStyles.durationData}>
                        {this.formatVideoDuration(
                          this.state.durationData[index]
                        )}
                      </Typography>
                    </Box>
                    <Box style={webStyles.info}>
                      <Typography>{video[1].title}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              <div
                ref={this.loaderRef}
                style={{ height: "20px", background: "transparent" }}
              />
            </>
          ) : (
            <>
              <Box display={"flex"} flexWrap={"wrap"}>
                {this.state.videoList.map((video) => {
                  return (
                    <Box style={{ position: "relative", width: "fit-content" }}>
                      <video
                        autoPlay={false}
                        key={video[0]}
                        style={{
                          height: "229px",
                          width: "229px",
                          marginRight: "4px",
                          marginTop: "4px",
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                      >
                        <source src={video[0]} style={{ height: "100%" }} />
                      </video>
                      <img
                        src={playIcon}
                        style={{
                          position: "absolute",
                          top: "40%",
                          right: "40%",
                          cursor: "pointer",
                        }}
                        data-test-id="related-video"
                        onClick={() => this.playVideo(video[0])}
                        alt=""
                      />
                    </Box>
                  );
                })}
              </Box>
              <div
                ref={this.loaderRef}
                style={{ height: "20px", background: "transparent" }}
              />
            </>
          )}
          
        </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles: { [key: string]: React.CSSProperties } = {
  IconBtnStyle: {
    height: "50px",
    width: "50px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    marginRight: "15px",
  },
  titleStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    color: "#282627",
  },
  durationData: {
    height: "16px",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    backgroundColor: "#000",
    opacity: "0.7",
    borderRadius: "9px",
    padding: "4px",
    color: "#fff",
    fontSize: "12px",
    bottom: "7px",
    right: "5px"
  },
  containerStyle: { marginTop: "50px" },
  videoStyle: {
    fontSize: "20px",
    color: "#0F172A",
    fontWeight: 700,
    margin: "35px 0px"
  },
  videoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  videoItem: {
    flexGrow: 1,
    flexBasis: '45%',
    marginBottom: '20px',
    marginRight: '2%',
    display: "flex"
  },
  video: {
    width: '167px',
    height: '100px',
    position: "relative"
  },
  info: {
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "15px"
  },
};
// Customizable Area End
