import React from "react";
import { Checkbox, styled } from "@material-ui/core";

interface Props {
  checked: boolean;
  dataTestId?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  style?: React.CSSProperties;
}

const StyledCheckbox = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    fontSize: "20px",
    color: "#925B5B",
  },
});

const CustomCheckbox = (props: Props) => {
  const { checked, dataTestId, onChange, name, style } = props;

  return (
    <StyledCheckbox
      data-test-id={dataTestId}
      checked={checked}
      onChange={onChange}
      name={name}
      style={{...style}}
    />
  );
};

export default CustomCheckbox;
