Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";


exports.apiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpDeleteMethod = "DELETE"
exports.httpPostMethod =  "POST";
exports.httpPutMethod =  "PUT";
exports.httpPatchMethod = "PATCH";
exports.getUserDetailsEndpoint = "account_block/accounts/logged_user";
exports.tokenExpireMsg = "Token has Expired";
exports.unauthorizedMsg = "Your session has expired, Please login again!";
exports.viewUserDetailsEndpoint = "account_block/accounts";
exports.validateProfileEndpoint = "account_block/accounts/check_profile_handle";
exports.editProfileEndpoint = "bx_block_settings/settings/edit_profile";
exports.editprofileImgEndpoint = "bx_block_settings/settings/edit_profile_image";
exports.fetchErrorMsg = "Something went wrong."
exports.profileUpdated = "Profile Updated successfully!"


exports.editProfile = "Edit Profile";
exports.shareProfile = "Share Profile";
exports.profile = "Profile";
exports.posts = "posts";
exports.followers = "followers";
exports.following = "following";
exports.noLocation = "No location entered";
exports.noHandle = "No handle selected";
exports.noBio = "No bio added";
exports.email = "Email";
exports.emailName = "email";
exports.socialLinks = "Social Media Links";
exports.facebooktxt = "FACEBOOK";
exports.twittertxt = "TWITTER";
exports.linkedln = "LINKEDIN";
exports.viewAll = "View all";
exports.copiedMsg = "Copied to clipboard";
exports.noPost = "No post yet";
exports.postTab = "Posts";
exports.galleryTab = "Gallery";
exports.repostTab = "Reposts";
exports.videosTab = "Videos";
exports.userParam = "navigationBarTitleText";
exports.editUserProfile = "EditUserProfile";
exports.editProfile = "Edit Profile";
exports.name = "name";
exports.textType = "textType";
exports.nameLabel = "Name";
exports.namePlaceholder = "Enter your name";
exports.emailType = "email";
exports.emailLabel = "Email address";
exports.emailPlaceHolder = "Enter your email";
exports.profileHandle = "profileHandle";
exports.handleLabel = "Profile Handle";
exports.handlePlaceholder = "Enter a profile handler";
exports.location = "location";
exports.locationLabel = "Location";
exports.locationPlaceholder = "Enter a location";

exports.bio = "bio";
exports.bioLabel = "Bio";
exports.bioPlaceholder = "Enter your bio";

exports.facebook = "facebook";
exports.facebookLabel = "Facebook";
exports.facebookPlaceholder = "Enter your Facebook account URL";

exports.twitter = "twitter";
exports.twitterLabel = "Twitter";
exports.twitterPlaceholder = "Enter your Twitter account URL";


exports.linkedin = "linkedin";
exports.linkedinLabel = "Linkedin";
exports.linkedinPlaceholder = "Enter your Linkedin account URL";
exports.linksTitle = "Social Media Links";

exports.availableMsg = "This handle is available."
exports.unavailableMsg = "This handle is already taken, Please try something else."
exports.debounceTime = 1000;
exports.submitType="submit";
exports.saveChanges = "Save Changes"

exports.regex = {
  TWITTER: /twitter\.com/,
  FACEBOOK: /facebook\.com/,
  LINKEDIN: /linkedin\.com/

}

exports.getFollowRequestEndpoint = "bx_block_profile/follow_relationships";
exports.updateFollowRelation = "bx_block_profile/follow_relationships";
exports.updateRequestStatusEndpoint = "bx_block_profile/follow_relationships";
exports.sendReqEndpoint = "bx_block_profile/follow_relationships";

// Customizable Area End

