Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start

// Content Type
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';


// API Method Type
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';



// API Endpoints
exports.getTermsCondsApiEndPoint = 'terms_and_conditions/latest_record';
exports.getAllTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.createTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.getAccountGroupsApiEndPoint = 'account_groups/groups';
exports.setTermsCondsAcceptanceApiEndPoint = 'terms_and_conditions/accept_and_reject';
exports.getTermsAndPrivacyEndpoint = "bx_block_terms_and_conditions/terms_and_conditions/latest_record";




// String Constants
exports.createTermsConds = 'Create New'
exports.created = 'Created'
exports.tickAccept = 'Please Tick To Accept';
exports.updateTermsConds = 'Update';
exports.acceptedUsers = 'Accepted Users';
exports.saveTermsConds = 'Save';
exports.termsCondsUpdatedMassage = 'Terms and Conditions Updated Successfully';
exports.termsCondsList = 'Terms & Conditions List';
exports.termsConds = 'Terms & Conditions';
exports.termsCondsUserList = 'Users That Accepted List';
exports.dateFormat = "MM-DD-YYYY";
exports.termPolicyType = "term";
exports.policyType = "policy";
exports.termParam = "navigationBarTitleText";
exports.termsAndConditions = "Terms & Conditions";
exports.privacyPolicy = "Privacy policy";
exports.termsAndConditionStatement = "I have read and agree to the terms and conditions";
exports.privacyPolicyStatement = "I have read and agree to the privacy policy";
exports.cancel = "Cancel";
exports.agree = "Agree";
exports.fetchErrorMsg = "Something went wrong!"

// Customizable Area End