import { styled } from '@material-ui/core';
import React from 'react'
import ReactPlayer from 'react-player'

interface Props {
  videoURL: string;
}

const StyledReactPlayer = styled(ReactPlayer)({

  "&.react-player": {
    width: "968px !important",
    height: "566px !important",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
  },

  "& .react-player__progress": {
    backgroundColor: "#red",
  },

  "& .react-player__progress-played": {
    backgroundColor: "red",
  }
});

const CustomVideoPlayer = (props: Props) => {

  const { videoURL } = props;

  return (
    <StyledReactPlayer
      url={videoURL}
      className='react-player'
      controls
      playing={true}
      muted
    />
  );
};


export default CustomVideoPlayer;
