import React from "react";

// Customizable Area Start
import {Box,Button, Container, Grid, IconButton, InputAdornment, TextField, withStyles}  from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { signinImg } from "./assets";
import ClearIcon from '@material-ui/icons/Clear';
import CustomToaster from "../../../components/src/CustomToaster";
import { imgPasswordInVisible, imgPasswordVisible } from "../../../blocks/email-account-registration/src/assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
// Customizable Area Start
export class EmailAccountLoginBlock extends EmailAccountLoginController {
// Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
     <Box style={{position:'relative' as 'relative'}} >
      <Box style={{position:'absolute' as 'absolute',top:'30px',left:'30px'}} >
        <Button style={webStyles.backButton} onClick={this.gotoSignupPage} ><ArrowBackIcon /></Button>
      </Box>
     </Box>
     <Box style={webStyles.parentDiv} >
      <Container>
        <Grid container spacing={4} >
          <Grid item sm={12} md={6}>
            <h1 style={{marginBottom:'-10px'}}>Sign In</h1>
            <p style={{marginBottom:'30px'}}>Login to your registered account</p>
            {this.state.errorMessage && (
                <Box style={webStyles.errorTextApi}>
                  <p style={{letterSpacing:'2px'}} >{this.state.errorMessage}</p>
                  <ClearIcon style={{cursor:'pointer'}} onClick={() => this.setState({ errorMessage: ""})} />
                </Box>
              )}
              <Box style={webStyles.fieldLabel}>
                <label style={webStyles.labelText} htmlFor="email">Email address</label>
                <TextField
                  style={webStyles.textInputField}
                  placeholder="Enter Email address"
                  InputProps={{
                      startAdornment: <InputAdornment position="start"><MailOutlineIcon  style={{color:'#DADADA'}} /></InputAdornment>
                  }}
                  variant="outlined"
                  type="text"
                  data-test-id="txtInputEmailLogin"
                  fullWidth
                  value={this.state.email}
                  name="email"
                  onChange={this.checkEmailValidation}
                  error={!!this.state.emailError}
                  helperText={this.state.emailError && <span style={webStyles.errorInputText}>{this.state.emailError}</span>}
               />
              </Box>

              <Box style={webStyles.fieldLabel} >
                <label style={webStyles.labelText} htmlFor="password">Password</label>
                <TextField
              style={webStyles.textInputField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><LockOutlinedIcon  style={{color:'#DADADA'}} /></InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleTogglePasswordShow}
                          edge="end"
                        >
                          {this.state.showPassword ? <img src={imgPasswordInVisible}/> : <img src={imgPasswordVisible} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Password"
                  variant="outlined"
                  type={this.state.showPassword ? 'text' : 'password'}
                  data-test-id="txtInputPassword"
                  fullWidth
                  value={this.state.password}
                  name="password"
                  onChange={this.handlePasswordValue}
                  error={!!this.state.passwordError}  // helperText={this.state.emailError}
                  helperText={this.state.passwordError && <span style={webStyles.errorInputText}>{this.state.passwordError}</span>}
                />
              </Box>
              <p data-test-id="forgotPasswordBtn" style={webStyles.forgotPassword} onClick={this.gotoForgotPassPage}>Forgot Password?</p>
              <Box>
                <Button style={webStyles.buttonStyle}  data-test-id="signInButton" onClick={this.handleLoginAccount} >Sign In</Button>
              </Box>
              <Box style={{textAlign:'center' as 'center'}} >
                  <p style={webStyles.commonTextStyle} >Don't have an account? <a href="" data-test-id="signupBtn" onClick={this.gotoSignupPage}  style={webStyles.anchorLinkStyles} >Create an Account</a> </p>

              </Box>
          </Grid>
          <Grid item sm={12} md={6} >
            <Box style={{textAlign:'end' as 'end'}}>
              <img src={signinImg} alt="sign in page image logo" />
            </Box>
          </Grid>
        </Grid>
        <CustomToaster/>
        <Loader isLoading={this.state.isLoading}/>
      </Container>
     </Box>
      </>
      // Customizable Area End
    );
  }

}
// Customizable Area Start
const webStyles = {
  parentDiv:{
    padding:'80px 0'
  },
  labelText:{
    fontSize:'16px',
    lineHeight:'24px',
    fontWeight:700,
  },
  textInputField: {
    height:'56px !important',
  },
  fieldLabel:{
    marginBottom:'30px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap:'10px'
  },
  errorInputText: {
    color: '#DC2626',
    fontSize: '14px',
    marginLeft: "-14px"
    
  },
  anchorLinkStyles:{
    color:'#925B5B',
    fontSize:'16px',
    fontWeight:600,
    lineHeight:'5px'
  },
  commonTextStyle:{
    fontSize:'16px',
    fontWeight:400

  },
  buttonStyle:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    fontSize:'20px',
    fontWeight:700,
    display: "inline-block",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    padding:'16px 0',
    borderRadius:'8px',
    textTransform:'capitalize' as 'capitalize'
  },
  backButton:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    minWidth:'44px',
    borderRadius:'8px'
  },
  errorTextApi:{
    background:'#FEE2E2',
    borderLeft:'4px solid #DC2626',
    borderRadius:'4px',
    color:'#DC2626',
    padding:'12px 10px',
    height:'48px',
    marginBottom:'20px',
    display:'flex',
    alignItems:'center',
    justifyContent:"space-between",
    gap:'10px',
    fontSize:'14px',
    lineHeight:'24px',
    fontWeight:400,
    cursor:'pointer'
 },
 forgotPassword:{
  textAlign:'end' as 'end',
  fontSize:'16px',
  fontWeight:700,
  cursor:'pointer'
 }
}
export default withStyles(webStyles)(EmailAccountLoginBlock);
// Customizable Area End
