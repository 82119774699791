import React from "react";
// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import { splashLogo } from "./assets";
import CustomToaster from "../../../components/src/CustomToaster";
// Customizable Area End

import SplashscreenController, { Props } from "./SplashscreenController";

export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return (
      <Box data-test-id="splash-container" style={webStyles.splashContainer}>
        <img src={splashLogo} alt="" />
        {!this.state.isOnline && (
          <Typography
            style={{
              color: "#FFF",
              fontSize: "25px",
              marginTop: "25px",
              fontFamily: "Montserrat",
            }}
          >
            You seem to be offline !{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={this.updateOnlineStatus}
            >
              Retry
            </span>
          </Typography>
        )}
        <button
          style={{ display: "none" }}
          data-test-id="retry-click"
          onClick={() => {
            this.updateOnlineStatus();
            this.handleRetry();
          }}
        />
        <CustomToaster />
      </Box>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const webStyles: { [key: string]: React.CSSProperties } = {
  splashContainer: {
    background: "linear-gradient(#BD9C9B 0%, #925B5B 100%)",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};
// Customizable Area End
