Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "uploadmedia3";
exports.labelBodyText = "uploadmedia3 Body";
exports.maxFileAllowed = 5;
exports.maxFileValidation = "You can only upload up to 5 files.";
exports.btnExampleTitle = "CLICK ME";
exports.MAX_IMAGE_SIZE = 30 * 1024 * 1024;
exports.MAX_VIDEO_SIZE = 100 * 1024 * 1024;
exports.MAX_FILE_SIZE = 201 * 1024 * 1024;
exports.invalidFileTypeMsg = "Only images and videos are permitted.";
exports.duplicateFileMsg = "Duplicate files cannot be uploaded.";
exports.uploadBtnTxt = "Add images or videos";
// Customizable Area End