import React from "react";
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Box, Container, Typography } from "@material-ui/core";

import PostCard from "../../../components/src/PostCard";
import Categoriessubcategories from "../../../blocks/categoriessubcategories/src/Categoriessubcategories";
import LoadingPostCard from "../../../components/src/LoadingPostCard";
import { legacyImage } from "./assets";
import ApiIntegration from "../../../blocks/apiintegration/src/ApiIntegration.web";
// Customizable Area End

import FeedController, { configJSON, Props } from "./FeedController";

export default class FeedPage extends FeedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      <Box data-test-id="landing-container">
        <NavigationMenu navigation={this.props.navigation} id={this.props.id} />
        <button style={{ display: "none" }} data-test-id="load-btn" onClick={() => this.loadMoreItems()} />
        <Container
          style={webStyles.containerStyle}
          data-test-id="feed-container"
        >

          <Categoriessubcategories navigation={this.props.navigation} id={""} sendCategoryData={this.getCategoryData} />
          <Typography style={webStyles.headerStyles} >{configJSON.chooseCategory}</Typography>
          <Box style={webStyles.categoriesContainer}>
            {
              this.state.categoriesData.map((category) => {
                return (
                  <Box
                    data-test-id="category-btn"
                    onClick={() => this.getPostsById(Number(category?.id), category?.attributes?.name)}
                    key={category.id}
                    style={{
                      ...webStyles.categoryTabStyle, background: `url(${category?.attributes?.category_image}) center center / cover no-repeat`,
                    }}>
                    <Typography style={{ cursor: "pointer", fontWeight: 600 }} >{category?.attributes?.name?.toUpperCase()}</Typography>
                  </Box>
                )
              })
            }
          </Box>
          {this.state.catName && <Typography style={webStyles.selectedCatStyle}>
            {this.state.catName}
          </Typography>}
          <ApiIntegration navigation={this.props.navigation} id={""} />
          {
            this.state.noPostFound && <>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                <img src={legacyImage} alt="" />
                <Typography
                  style={{
                    fontSize: "20px",
                    marginTop: "15px",
                    marginBottom: "15px",
                    color: "#292B29",
                    fontWeight: 600,
                  }}
                >{`No Posts yet. Be the first one to post here!`}</Typography>
              </Box>

            </>
          }
          {this.state.postData?.length === 0 && this.state.isLoading &&  <><LoadingPostCard /> <LoadingPostCard /> <LoadingPostCard /></> }
          {
              this.state.postData?.map((post, index) => {
                return (
                  <React.Fragment key={post?.id}>
                    <PostCard
                      description={post.attributes?.description}
                      liked={post.attributes?.liked}
                      likeCount={post.attributes?.like_count}
                      likeBy={post.attributes?.like_by}
                      likeByUser={post.attributes?.like_by_user}
                      postByUser={post.attributes?.post_by_user}
                      userProfile={post.attributes?.user_profile}
                      postMedia={post.attributes?.images_and_videos}
                      commentCount={post.attributes?.comment_count}
                      postId={Number(post.id)}
                      navigationFn={this.navigateToPage}
                      isRepost={Boolean(post.attributes?.is_repost?.post)}
                      repostData={post.attributes?.is_repost?.post}
                      postData={post}
                    />
                  </React.Fragment>)
              })
          }
        </Container>
        <div
          ref={this.loaderRef}
          style={{ height: "20px", background: "transparent" }}
        />
      </Box>
    );
  }
}

// Customizable Area Start

const webStyles: { [key: string]: React.CSSProperties } = {
  containerStyle: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  categoriesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: "542px",
    marginBottom: "50px"
  },
  headerStyles: {
    color: "#111827",
    fontSize: "28px",
    fontWeight: 700,
    marginBottom: "40px"
  },
  selectedCatStyle: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "50px"
  },
  quoteDescriptionStyle: {
    fontSize: "16px",
    color: "#475569",
    fontWeight: 400,
    textAlign: "center",
    marginTop: "25px",
  },

  paperStyle: {
    backgroundColor: "#F5F1EF",
    height: "360px",
    width: "340px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    marginTop: "20px",
    marginInline: "25px",
  },
  categoryTabStyle: {
    flexGrow: 1,
    flexBasis: '45%',
    marginBottom: '20px',
    marginRight: '2%',
    display: "flex",
    height: "60px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "36px",
    color: "#fff",
    textTransform: "capitalize",
    fontFamily: "Montserrat",
    cursor: "pointer"
  }
};

// Customizable Area End
