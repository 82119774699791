import React from "react";
import { Toaster, ToastPosition } from "react-hot-toast";

interface Props {
  position?: ToastPosition;
}

const CustomToaster = (props: Props) => {
  const { position } = props;

  return (
    <Toaster
      position={position ?? "bottom-center"}
      toastOptions={{
        duration: 5000,
      }}
      containerStyle={{ marginBottom: "30px" }}
    />
  );
};

export default CustomToaster;
