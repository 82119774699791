import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { callApi } from "../../../components/src/Utilities";
import { toast } from "react-hot-toast";


interface IDislikeResponse {
  message: string;
}

interface ILikeAttributes {
  likeable_id: number;
  likeable_type: string;
  like_by_id: number;
  created_at: string;
  updated_at: string;
}

interface ILikeDataItem {
  id: string;
  type: string;
  attributes: ILikeAttributes;
}

interface ILikeData {
  data: ILikeDataItem;
}



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  postId?: number;
  isLiked?: boolean;
  height?: number;
  width?: number;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isCurrentlyLiked: boolean | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Likeapost2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isCurrentlyLiked: this.props.isLiked
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorReponse || responseJson?.errors || responseJson?.error) {
      this.apiFailureCallBacks();
    }

    else if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }


    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  postaLikeId: string = "";
  dislikePostId: string = "";

  postALike = async () => {
 
    const authToken = await getStorageData("token");
    const { postId } = this.props;
    
    const payload = {
      data: {
        attributes: {
          likeable_type: configJSON.likeTypePost,
          likeable_id: postId ?? 53,
        },
      },
    };

    this.postaLikeId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.httpPostMethod,
      endPoint: `${(configJSON.postLikeEndpoint as string)}`,
      body: payload,
      headers: { "token": authToken},
    }, runEngine);
    
  }

  dislikePost = async () => {
 
    const authToken = await getStorageData("token");
    const { postId } = this.props;

    this.dislikePostId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.httpDeleteMethod,
      endPoint: `${(configJSON.dislikePostEndpoint as string)}/${postId ?? 53}`,
      headers: { "token": authToken},
    }, runEngine);
    
  }

  apiFailureCallBacks = () => {
    toast.error(configJSON.globalErrorMessage);
  }

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ILikeData & IDislikeResponse) => {
    if (apiRequestCallId === this.postaLikeId) {
      this.handleLikePostCallback(responseJson)
    }
    else if (apiRequestCallId === this.dislikePostId){
      this.handleDislikePostCallback(responseJson);
    }
  }


  handleLikePostCallback = (responseJson: ILikeData) => {
    this.setState({
      isCurrentlyLiked: responseJson.data ? true : false
    })
  }

  handleDislikePostCallback = (responseJson: IDislikeResponse) => {
    this.setState({
      isCurrentlyLiked: responseJson.message === configJSON.deleteSuccesMsg ? false : true
    })
  }


  handleLikeBtnClick = () => {

    const { isCurrentlyLiked } = this.state;
    isCurrentlyLiked ? this.dislikePost() : this.postALike();

  }

  // Customizable Area End
}
