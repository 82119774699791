Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.getCategoriesEndpoint = "bx_block_categories/categories";
exports.fetchErrorMsg = "Something went wrong!";
exports.tokenExpireMsg = "Token has Expired";
exports.unauthorizedMsg = "Your session has expired, Please login again.";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"


// Customizable Area End