import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { callApi } from "../../../components/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";
import { createRef, RefObject } from "react";
import { Category } from "../../../blocks/categoriessubcategories/src/CategoriessubcategoriesController";

// Customizable Area Start

interface IApiResponse {
  data: IPostData[];
  meta: IMetaData;
}

export interface IPostData {
  id: string;
  type: string;
  attributes: IPostAttributes;
}

interface IPostAttributes {
  id: number;
  name: string | null;
  description: string;
  body: string;
  location: string | null;
  account_id: number;
  category_id: number;
  sub_category_id: number | null;
  content_type: string | null;
  account_privacy: string | null;
  post_archive: string | null;
  post_action: string;
  created_at: string;
  updated_at: string;
  title: string | null;
  model_name: string;
  liked: boolean;
  like_count: number;
  like_by: string;
  like_by_user: IUser;
  post_by_user: IUser;
  comment_count: number;
  images_and_videos: IMedia[];
  media: [];
  user_profile: string;
  is_repost: IIsRepost;
}

interface IUser {
  id: number;
  email: string;
}

interface IMedia {
  id: number;
  url: string;
}

interface IIsRepost {
  post: {
    data: IPostData;
  };
}

interface IMetaData {
  current_page: number;
  next_page: number;
  prev_page: number;
  total_pages: number;
  total_count: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currPage: number;
  perPage: number;
  isLoading: boolean;
  postData: IPostData[];
  categoriesData: Category[];
  catName: string;
  isCategoryCall: boolean;
  selectedCatId: number;
  noPostFound: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FeedController extends BlockComponent<Props, S, SS> {
  observer: IntersectionObserver | null = null;
  loaderRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      currPage: 1,
      perPage: 10,
      isLoading: false,
      postData: [],
      categoriesData: [],
      catName: "",
      isCategoryCall: false,
      selectedCatId: 0,
      noPostFound: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.setState({
      isLoading: false,
    });
    if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getPostsId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getPosts();
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.loadMoreItems();
      }
    });
    if (this.loaderRef.current) {
      this.observer.observe(this.loaderRef.current);
    }
  }

  async componentWillUnmount() {
    this.observer?.unobserve(this.loaderRef.current || new HTMLDivElement());
  }

  loadMoreItems = () => {
    this.setState(prevState => ({ currPage: prevState.currPage + 1 }), () => {
      if(this.state.isCategoryCall){
        this.getPostsByCategoryId(this.state.selectedCatId)
      }
      else {
        this.getPosts();
      }
    });
  };


  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson: IApiResponse & {message: string}
  ) => {
    if (apiRequestCallId === this.getPostsId) {
      if (responseJson?.message === "Records not found") {
        this.setState({
          noPostFound: true
        })
      }
      else {
        this.setState((prevState) => ({ postData: prevState?.postData && responseJson?.data ? [...prevState?.postData, ...responseJson?.data] : [], noPostFound: false }))
      }
    }
  };

  navigateToPage = (pathName: string, param?: string | number) => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pathName
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
    this.send(navigationMessage);
  };

  getPosts = async () => {
    const { currPage, perPage } = this.state;
    const authToken = await getStorageData("token");
    if (currPage === 1) {
      this.setState({ isLoading: true });
    }
    if (!this.state.isCategoryCall) {
      this.getPostsId = callApi(
        {
          contentType: configJSON.apiContentType,
          method: configJSON.methodTypes.HTTP_GET,
          endPoint: `${configJSON.endpoints.GET_POST as string
            }?page=${currPage}&per_page=${perPage}`,
          headers: { token: authToken },
        },
        runEngine
      );
    }
  };

  getRelatedData = async () => {
    const { currPage, perPage } = this.state;
    const authenticationToken = await getStorageData("token");

    this.getPostsId = callApi(
      {
        contentType: configJSON.validationApiContentType,
        endPoint: `${
          configJSON.RelatedData as string
        }?page=${currPage}&per_page=${perPage}`,
        headers: { token: authenticationToken },
        method: "GET",

      },
      runEngine
    );
  };


  getCategoryData = (categoryData: Category[]) => {
    this.setState({
      categoriesData: categoryData
    })
  }


  getPostsById = (catId: number, catName: string) => {
    this.setState({
      catName: catName,
      currPage: 1,
      postData: [],
      selectedCatId: catId,
      isCategoryCall: true,
      noPostFound: false
    }, () => this.getPostsByCategoryId(catId));

  }


  getPostsByCategoryId = async (catId: number) => {
    const { currPage, perPage } = this.state;
    const authToken = await getStorageData("token");
    if (currPage === 1) {
      this.setState({ isLoading: true });
    }

    this.getPostsId = callApi(
      {
        contentType: configJSON.apiContentType,
        method: configJSON.methodTypes.HTTP_GET,
        endPoint: `${
          configJSON.endpoints.GET_POST_BY_ID as string
        }?page=${currPage}&per_page=${perPage}&category_id[]=${catId}`,
        headers: { token: authToken },
      },
      runEngine
    );
  }

  // Customizable Area End
}
