import React from "react";
// Customizable Area Start

import {
  Box,
  IconButton,
  Grid,
  Typography,
  Container,
  Hidden,
  styled,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { twofa } from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomButton from "../../../components/src/CustomButton";
import Loader from "../../../components/src/Loader.web";
import CustomToaster from "../../../components/src/CustomToaster";
import CustomOtpInput from "../../../components/src/CustomOtpInput";
import { goToPrevPage } from "../../../components/src/Utilities";

const configJSON = require("./config");
const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});

const StyledTypography = styled(Typography)({
  "&.page-title": {
    fontSize: "48px",
    fontWeight: 700,
    lineHeight: "56px",
    color: "#292B29",
    "@media(max-width:900px)": {
      fontSize: "40px",
      lineHeight: "50px",
    },
    "@media(max-width:600px)": {
      fontSize: "30px",
      lineHeight: "40px",
    },
  },

  "&.sublabel-text": {
    fontSize: "20px",
    fontWeight: 400,
    color: "#282627",
    marginTop: "5px",
    "@media(max-width:900px)": {
      fontSize: "18px",
    },
    "@media(max-width:600px)": {
      fontSize: "16px",
    },
  },
});

// Customizable Area End

import MobileAccountLoginController, {
  Props,
} from "./MobileAccountLoginController";

export default class MobileAccountLoginBlock extends MobileAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    return (
      <ThemeProvider theme={theme}>
        <IconButton style={webStyles.iconButton} onClick={() => goToPrevPage()}>
          <ArrowBackIcon htmlColor="#fff" />
        </IconButton>

        <Container data-test-id="main-container">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item sm={12} md={6} style={webStyles.parentGridStyle}>
              <StyledTypography className="page-title" variant={"h2"}>
                Two- Factor <br /> Authentication
              </StyledTypography>

              <StyledTypography className="sublabel-text">
                {configJSON.pageInfoMsg}
              </StyledTypography>
              <Box style={webStyles.parentContainer}>
                <CustomOtpInput
                  data-test-id="handleChangeOTP"
                  value={this.state.otpData}
                  onChange={this.handleOtpChange}
                  digitCount={configJSON.defaultOtpDigit}
                  isOtpinvalid={this.state.isOtpInvalid}
                  invalidOtpMsg={this.state.validationMsg}
                />
              </Box>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={4}
                style={webStyles.btnContainer}
              >
                <Grid item xs={12}>
                  <CustomButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    dataTestId="verify-btn"
                    btnText={configJSON.verify}
                    onClick={this.verfiyOTP}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomButton
                    variant="outlined"
                    color="primary"
                    fullWidth
                    dataTestId="btn-cancel"
                    btnText={configJSON.goBack}
                    onClick={() =>
                      this.navigateToPage(configJSON.emailAccountRegistration)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item sm={12} md={6}>
                <Box style={webStyles.imgContainer}>
                  <img style={webStyles.imgStyle} src={twofa} alt="no-img" />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Container>

        <Loader isLoading={this.state.isLoading} />
        <CustomToaster />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles: { [key: string]: React.CSSProperties } = {
  iconButton: {
    height: "44px",
    width: "44px",
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    borderRadius: "8px",
    padding: "10px",
    lineHeight: "32px",
    margin: "40px",
  },
  parentGridStyle: {
    width: "100%",
  },
  parentContainer: {
    marginTop: "25px",
  },
  btnContainer: { marginTop: "35px" },
  imgStyle: { height: "100%" },
  imgContainer: { textAlign: "end" },
};

// Customizable Area End
