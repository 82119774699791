import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  styled,
  Divider 
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { navLogo } from "./assets";
import MenuIcon from '@material-ui/icons/Menu';
import CustomButton from "../../../components/src/CustomButton";
import { getStorageData } from "../../../framework/src/Utilities";


const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif !important",
  },
});



export const navigationData = [
  { id: 1, linkName: "Create", navigationProp: "PostCreation" },
  { id: 3, linkName: "Contact", navigationProp: "Contactus" },
  { id: 4, linkName: "Settings", navigationProp: "Settings2" },
  { id: 5, linkName: "Profile", navigationProp: "UserProfileBasicBlock" },
]


const StyleIconButton = styled(IconButton)({
  "& .brand-logo": {
    "@media(max-width:750px)": {
      display: "none",
    },
  },

  "& .mobile-menu": {
    display: "none",
    "@media(max-width:750px)": {
      display: "block",
    },
  },
});


const StyledBox = styled(Box)({

  "&.desktop-container": {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '25px',
    "@media (max-width: 750px)": {
      display: 'none',
    },
  },

  "&.mobile-container": {
    display: 'none',
    alignItems: 'flex-start',
    flexDirection: "column",
    marginLeft: '25px',
    "@media (max-width: 750px)": {
      display: 'flex',
    },
  },

});


const StyledTypography = styled(Typography)({
  fontSize: "14px",
  marginRight: "20px",
  "&:hover": {
    color: "#925B5B !important",
    fontWeight: "bold",
    cursor: "pointer"
  },
  "@media (max-width: 750px)": {
    margin: "8px 0px",
    textAlign: "left",
    fontSize: "16px"
  },

});


// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  navigationItems = () => {
    return navigationData.map((navItem) => {
      return (
        <StyledTypography
          key={navItem.id}
          data-test-id='nav-item'
          style={{color: "#334155"}}
          onClick={() => this.handleNavigation(navItem.navigationProp)}
        >
          {navItem.linkName}
        </StyledTypography>
      );
    });
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <AppBar
          position="static"
          data-test-id="app-bar"
          style={webStyles.appBarStyles}
        >
          <Toolbar>
            <StyleIconButton edge="start" aria-label="menu">
              <img className="brand-logo" data-test-id="home-logo" src={navLogo} alt="" onClick={() => this.handleNavigation("LandingPage")} />
              <MenuIcon
                data-test-id = "btnMenu"
                className="mobile-menu"
                htmlColor="#925B5B"
                fontSize="large"
                onClick={() => this.switchDrawer(true)}
              />
            </StyleIconButton>
            <StyledBox
              className="desktop-container"
            >
              {this.navigationItems()}
            </StyledBox>
          </Toolbar>
          {this.getIsLoggedIn() && <Box>
            <CustomButton
              variant="outlined"
              color="primary"
              fullWidth
              dataTestId="btn-signup"
              btnText={configJSON.btnText}
              onClick={() => this.handleNavigation(configJSON.signupPath)}
              style={webStyles.btnSignupStyles}
            />
            <CustomButton
              variant="contained"
              color="primary"
              fullWidth
              dataTestId="btn-signup"
              btnText={"Login"}
              onClick={() => this.handleNavigation(configJSON.signinPath)}
              style={webStyles.btnSignupStyles}
            />
          </Box>}
          
          <Drawer
            open={this.state.isDrawerOpen}
            onClose={() => this.switchDrawer(false)}
          >
            <img src={navLogo} data-test-id="home-logo" style={webStyles.drawerImgStyle} alt="" onClick={() => this.handleNavigation("LandingPage")}  />
            <Divider style={{backgroundColor: "#925B5B", marginBottom: "10px"}}/>
            <StyledBox
              className="mobile-container"
            >
              {this.navigationItems()}
            </StyledBox>
          </Drawer>
        </AppBar>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles: { [key: string]: React.CSSProperties } = {

  appBarStyles: {
    background: "#fff",
    height: "76px",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  btnSignupStyles: {
    width: "145px",
    height: "45px",
    padding: "10px, 16px, 10px, 16px",
    fontSize: "16px",
    marginRight: "26px",
  },

  drawerStyle: {
    width: "40%"
  },

  drawerImgStyle: {
    height: "37px",
    width: "162px",
    margin: "25px 35px 35px 35px"
  }

};


// Customizable Area End
