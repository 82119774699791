import {
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import React from "react";
import CustomButton from "./CustomButton";
import Webcam from "react-webcam";

interface Props {
  isOpen: boolean;
  processImg: (imageSrc: string | null | undefined) => void;
  handleClose: (toggle: boolean) => void;
}

const videoConstraints = {
  facingMode: "user",
  height: 200,
  width: 200,
};

const CustomWebCamModal = (props: Props) => {
  const { isOpen, processImg, handleClose } = props;

  let imgSrc: string | null | undefined;

  const webcamRef = React.useRef<Webcam>(null);
  const capture = React.useCallback(() => {
     imgSrc = webcamRef.current?.getScreenshot();
     processImg(imgSrc);
  }, [webcamRef]);
  
  return (
    <>
      {isOpen && (
        <>
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            maxWidth="sm"
            fullWidth
            onClose={() => handleClose(false)}
          >
            <DialogContent style={{display: "flex", justifyContent: "center"}}>
              <Webcam
                audio={false}
                height={250}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={250}
                videoConstraints={videoConstraints}
                style={{borderRadius: "15px"}}
              />
            </DialogContent>
            <DialogActions>
              <CustomButton
                variant="contained"
                onClick={() => capture()}
                color="primary"
                btnText="Capture"
                dataTestId="btn-submit"
                style={{
                  margin: "10px 15px",
                  height: "56px",
                  fontSize: "16px",
                }}
              />
              <CustomButton
                variant="outlined"
                onClick={() => handleClose(false)}
                color="primary"
                btnText="Cancel"
                dataTestId="btn-submit"
                style={{
                  margin: "10px 15px",
                  height: "56px",
                  fontSize: "16px",
                }}
              />
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default CustomWebCamModal;
