import React from "react";

// Customizable Area Start

import EmailAccountRegistrationController,{Props, configJSON} from "./EmailAccountRegistrationController";
import { Box, Button, Checkbox, Container, Grid, IconButton, InputAdornment, styled, TextField, withStyles } from "@material-ui/core";
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CustomToaster from "../../../components/src/CustomToaster";
import ClearIcon from '@material-ui/icons/Clear';
import { imgPasswordInVisible, imgPasswordVisible, signupPageImage } from "./assets";
import Loader from "../../../components/src/Loader.web";


const StyledInputField = styled(TextField)({
  "& .MuiFormHelperText-root": {
    marginLeft: "0px !important",
  },
});

// Customizable Area End

export class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPasswordCriteria() {
    const { hasUpperCase, hasSpecialChar, hasNumber, hasMinLength } = this.state;
    if (!this.state.showCriteria) return null;

    return (
      <Box>
        <div style={webStyles.passwordCriteria}> <CheckBoxIcon style={{color: hasUpperCase ? '#34D399': '#94A3B8'}} /> At least one upper case and one lower case letter in password field</div>
        <div style={webStyles.passwordCriteria}> <CheckBoxIcon style={{color: hasSpecialChar ? '#34D399': '#94A3B8'}} /> At least one special character</div>
        <div style={webStyles.passwordCriteria}> <CheckBoxIcon style={{color: hasNumber ? '#34D399': '#94A3B8'}} /> At least one number</div>
        <div style={webStyles.passwordCriteria}> <CheckBoxIcon style={{color: hasMinLength ? '#34D399': '#94A3B8'}} /> Minimum character length is 8 characters</div>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <Box style={webStyles.parentBox}>
        <Container>
      <Grid  container spacing={4} alignItems="center">
        <Grid item sm={12} md={6} >
          <Box>
            <h1 style={{ marginBottom:'60px'}} >Sign Up</h1>
            {this.state.showAlertBox && (
                <Box style={webStyles.errorTextTc}>
                  <p style={{letterSpacing:'2px'}} >You have to agree with Privacy Policy and Terms and Conditions to Sign up</p>
                  <ClearIcon style={{cursor:'pointer'}} onClick={this.closeErrorPopup} />
                </Box>
              )}
            <Box style={webStyles.fieldLabel}>
              <label style={webStyles.labelStyles} htmlFor="email">Email address</label>

            <StyledInputField
              style={webStyles.textField}
              placeholder="Enter Email address"
              InputProps={{
                  startAdornment: <InputAdornment position="start"><MailOutlineIcon  style={{color:'#DADADA'}} /></InputAdornment>
              }}
              variant="outlined"
              type="text"
              data-test-id="txtInputEmail"
              fullWidth
              value={this.state.email}
              name="email"
              onChange={this.checkEmailValidation}
              error={!!this.state.emailError}  // helperText={this.state.emailError}
              helperText={this.state.emailError && <span style={webStyles.errorText}>{this.state.emailError}</span>}
            />
            </Box>
            <Box style={webStyles.fieldLabel}>
              <label style={webStyles.labelStyles} htmlFor="password">Password</label>

              <StyledInputField
              style={webStyles.textField}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><LockOutlinedIcon  style={{color:'#DADADA'}} /></InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {this.state.showPassword ? <img src={imgPasswordInVisible}/> : <img src={imgPasswordVisible} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter password"
                  variant="outlined"
                  type={this.state.showPassword ? 'text' : 'password'}
                  data-test-id="txtInputPassword"
                  fullWidth
                  value={this.state.password}
                  name="password"
                  onChange={this.handlePasswordChange}
                  onFocus={this.handleToggleCriteriaVisibility}
                  onBlur={this.handleToggleCriteriaVisibility}
                  error={!!this.state.isPasswordInvalid}  // helperText={this.state.emailError}
                  helperText={this.state.isPasswordInvalid && <span style={webStyles.errorText}>{configJSON.validationMessageP}</span>}
                />
              </Box>
              <div style={{marginBottom:'16px'}} >
                 {this.state.showCriteria && this.renderPasswordCriteria()}
              </div>

              <Box style={{marginBottom:'30px'}}>
                <Checkbox
                  style={{color:'#925B5B'}}
                  checked={this.state.isChecked}
                  onChange={this.handleCheckboxChange}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  data-test-id="txtInputCheckbox"
                />
                <span>I hereby consent to the <span onClick={() => this.navigateToPage("policy")} style={webStyles.anchorTagStyles}>Privacy Policy</span> and <span onClick={() => this.navigateToPage("term")} style={webStyles.anchorTagStyles}>Terms of Use.</span></span>
              </Box>

              <Box>
                <Button style={webStyles.buttonStyles} disabled={Boolean(this.state.emailError) || Boolean(this.state.passwordError)} onClick={this.handleCreateAccount} data-test-id="submitButton" >Create Account</Button>
              </Box>
              <Box style={{textAlign:'center' as 'center'}} >
                  <p style={webStyles.commonTextStyles} >Already have an account? <a href="" onClick={this.gotoLoginPage} style={webStyles.anchorTagStyles} >Sign In</a> </p>

              </Box>
            </Box>
          </Grid>
        <Grid item sm={12} md={6} >
          <Box style={{textAlign:'end' as 'end'}}>
            <img src={signupPageImage} alt="sign up page image logo" />
          </Box>
        </Grid>
      </Grid>
      <CustomToaster/>
      <Loader isLoading={this.state.isLoading}/>
    </Container>
  </Box>
 </>
      // Customizable Area End
    );
  }

}

 // Customizable Area Start
 const webStyles = {
  parentBox:{
    padding:'80px 0'
  },
  labelStyles:{
    fontSize:'16px',
    lineHeight:'24px',
    fontWeight:700,
  },
  textField: {
    // marginBottom: '16px',
    height:'56px !important', // Added margin for spacing between fields
    borderRadius:'10px !important'
  },
  fieldLabel:{
    marginBottom:'40px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap:'10px'
  },
  errorText: {
    color: '#DC2626',
    fontSize: '14px',
    marginLeft: '0px'
    
  },
  passwordCriteria:{
    display :'flex',
    gap:'5px',
    alignItems:'center'
  },
  anchorTagStyles:{
    color:'#925B5B',
    fontSize:'16px',
    fontWeight:600,
    lineHeight:'5px',
    textDecoration: "underline",
    cursor: "pointer"
  },
  commonTextStyles:{
    fontSize:'16px',
    fontWeight:400

  },
  buttonStyles:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    fontSize:'20px',
    fontWeight:700,
    display: "inline-block",
    width: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    padding:'16px 0',
    borderRadius:'8px',
    textTransform:'capitalize' as 'capitalize'
  },
  backButtonStyle:{
    background: "linear-gradient(270deg, #BD9C9B 0%, #925B5B 100%)",
    color:'#fff',
    minWidth:'44px',
    borderRadius:'8px'
  },
  errorTextTc:{
    background:'#FEE2E2',
    borderLeft:'4px solid #DC2626',
    borderRadius:'4px',
    color:'#DC2626',
    padding:'12px 10px',
    height:'65px',
    marginBottom:'20px',
    display:'flex',
    alignItems:'center',
    justifyContent:"space-between",
    gap:'10px',
    fontSize:'14px',
    lineHeight:'24px',
    fontWeight:400,
    cursor:'pointer'
 }
}

export default withStyles(webStyles)(EmailAccountRegistrationBlock);
 // Customizable Area End
